import React, { useEffect, useState } from 'react'
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import { Controller, useForm } from 'react-hook-form';
import { Sidebar } from 'primereact/sidebar';
import { UserService } from '../../services';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog } from 'primereact/dialog';
import { BlockSpinner } from '../block-spinner';

export default function RecoverPassword({onClose, showRecoveryPassword=false}:{onClose?:any, showRecoveryPassword?:boolean}) {
  const [showSide, setShowSide] = useState(showRecoveryPassword)
  const [showMessage, setShowMessage] = useState(false);
  const [blocked, setBlocked] = useState(false);
  
  const schema = z.object({
    email: z.string({required_error:t`Ingrese un email`}).email({message:t`Ingrese un email`})
  });
  const { handleSubmit, control, formState: { errors } }:any = useForm({defaultValues:{email:""},resolver: zodResolver(schema) });
  
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const handleClose = (showLogin=false)=>{
    onClose && onClose({ showLogin })
    setShowSide(false);
  }
  useEffect(()=>{
    setShowSide(showRecoveryPassword)
  },[showRecoveryPassword])
  const onSubmit = async (e:any)=>{
    setBlocked(true)
    UserService
    .sendRecoveryPassword(e.email)
    .then(()=>setShowMessage(true))
    .finally(()=>setBlocked(false));
  }
  return (
    <Sidebar blockScroll={true} dismissable={true} visible={showSide} position="right" onHide={() => handleClose()} className="w-full md:w-3">
      <div id="FORGET-PASSWORD" className="">
        <h3><Trans>¿Olvidaste tu contraseña?</Trans></h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formgrid grid">
              <div className="field col-12">
                  <span className="font-medium opacity-70"><Trans>Por favor, ingresa tu correo electrónico para enviarte un enlace para que puedas crear una nueva contraseña.</Trans></span>
              </div>
              <div className="field col-12">
                <Controller name="email" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-user" />
                      <InputText id={field.name} {...field} placeholder={t`Correo electrónico`} className="w-full" />
                    </span>
                  )} />
                  {getFormErrorMessage("email")}
              </div>
              <div className="field col-12">
                  <Button className="font-semibold w-full" label={t`Enviar`} />
              </div>
          </div>
        </form>
        
      </div>
      <Dialog visible={showMessage} 
              onHide={() => setShowMessage(false)} 
              position="top" 
              footer={ <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => handleClose()} /></div>} 
              showHeader={false} 
              breakpoints={{ '960px': '80vw' }} 
              className='messageConfirmation'>

        <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
            <h5>Se envió un mail, para recuperar su contraseña</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                <Trans>Se ha enviado un correo para recuperar su contraseña, por favor revise su bandeja de entrada y siga las instruciones descritas.</Trans> .
            </p>
        </div>
      </Dialog>
      <BlockSpinner  uiBlocked={blocked} />
    </Sidebar>
    
  )
}
