import { createContext, useContext, useState }  from 'react'
import { useLocalStorage } from 'primereact/hooks';
export interface ICartTotal {
    productsQuantity:number;
    visitsAllQuantity: number;
    totalPrice: number;
  }

export interface ICartMonasterioContext {
    products: any;
    setProducts(products: any): void;
    total: ICartTotal;
    setTotal(products: any): void;
    headerPage:any
    setHeaderPage:any
  }

const CartMonasterioContext = createContext<ICartMonasterioContext | undefined>(undefined);

const useCartMonasterioContext = () : ICartMonasterioContext =>{
    const context = useContext(CartMonasterioContext);
    if (!context){
        throw new Error('useCartMonasterioContext must be used within a CartMonasterioContext');
    }
    return context;
}

const CartMonasterioProvider = (props: any)=>{
    const [headerPage, setHeaderPage] = useState(null)
    const cartMonasterioProducts:any = localStorage.getItem("cartMonasterioProducts")
    const cartMonasterioTotal:any = localStorage.getItem("cartMonasterioTotal")
    
    const defaultProducts = cartMonasterioProducts? JSON.parse(cartMonasterioProducts):{}
    const defaultTotal = cartMonasterioTotal? 
      JSON.parse(cartMonasterioTotal) : {
        productsQuantity:0,
        visitsAllQuantity:0,
        totalPrice:0 }

    const [products, setProducts] :any = useLocalStorage(defaultProducts, "cartMonasterioProducts");
    const [total, setTotal] :any = useLocalStorage(defaultTotal, "cartMonasterioTotal");

    const CartContextValue : ICartMonasterioContext = {
        setProducts,
        products,
        total,
        setTotal,
        headerPage, 
        setHeaderPage
    }
    return <CartMonasterioContext.Provider value={CartContextValue} {...props} />;
}

export { CartMonasterioProvider, useCartMonasterioContext };
