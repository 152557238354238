import React from "react";
import { InputText } from "primereact/inputtext";
import { useEffect, useState } from "react";
import { Sidebar } from 'primereact/sidebar';
import { i18n } from "@lingui/core";
import { Trans, t } from "@lingui/macro";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Controller, useForm } from "react-hook-form";
import { InputTextarea } from 'primereact/inputtextarea';
import { BlockSpinner } from "./block-spinner";
import { EVisistService, TicketService } from "../services";
import { useLingui } from '@lingui/react';
type VisitDetailProp ={
  componentRoute?:boolean 
  visitor?:any,
  onClose?:any,
  validate?:boolean
}

const VisitDetail =({ componentRoute=false, visitor, onClose, validate=false }:VisitDetailProp,)=> {
  useLingui()
  const [visit, setVisit]:any = useState()
  const [visitants, setVisitants]:any = useState()
  const [showMessage, setShowMessage]:any = useState();
  const [blocked, setBlocked]:any = useState(false);
  const [dismissable, setDismissable]= useState(false);
  const [visible, setVisible]= useState(true);
  const { handleSubmit, control, formState: { errors } }:any = useForm({defaultValues:{nota:""}})
  
  const messages : any = {
    "1":{ title:`Ticket Expirado`, message:`El ticket esta fuera de la fecha y hora indicada`, showForm: true, color: 'orange-500' },
    "2":{ title:`Ticket Pendiente`, message:`Su ticket está proximo a su visita`, showForm: true, color: 'orange-500' },
    "3":{ title:`Ticket dentro de la hora`, message:`Bienvenido`, showForm: true, color: 'green-500' }
  }
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const nationalityTemplate = (vn:any) =>{
    ///icon-extranjero.svg
    return (
      <div className="col-12 pt-4 pb-3 flex">
        <img alt="" className="mr-2" src="/icon-peru.svg" /><span className="font-medium opacity-70">{ vn.tipoNacionalidad }</span>
      </div> )
  }
  const ageTemplate = ((vn:any)=>{
    return (
      <>
         <div className="col-8">
            <span className="w-full inline-block font-semibold">{vn.tipoEdad}</span>
            <span className="w-full inline-block text-sm opacity-80">{i18n.number(vn.precioTotal, { style: "currency", currency:"PEN" })}</span>
          </div>
          <div className="col-4">
            <InputText placeholder="0" className="w-full flex-1 text-center" value={vn.cantidadVisitantes} />
          </div>
      </>
    )
  })

  useEffect(()=>{
    
    if (!componentRoute){
      if (visitor){
        const vs = visitor?.visitantes?.reduce((group:any, v:any)=>{
        const { tipoNacionalidadId, tipoNacionalidad  } = v;
          group[tipoNacionalidadId] = group[tipoNacionalidadId] ?? { tipoNacionalidadId, tipoNacionalidad, ages:[] };
          group[tipoNacionalidadId].ages.push(v);
          return group;
        },{})
        if(vs) setVisitants(Object.values(vs))
        setVisit(visitor);
        
        if (  validate 
          &&  visitor.ingreso !== true 
          &&  visitor.realTimeState !== null 
          &&  visitor.realTimeState !== undefined){
          
          setDismissable(true)
          setShowMessage(messages[visitor.realTimeState])
        }
        else if (visitor.ingreso === true ){
          setDismissable(true)
          setShowMessage({title:`Ticket Usado`, message:`El ticket fue usado`, showForm: false, color: 'red-500' })
        }
      }
    }
  },[])
  const cantidadVisitantes = ()=> visit?.visitantes.reduce((a:any,c:any)=> a + c.cantidadVisitantes,0);
  const _close =()=>{
    setVisible(false);
    onClose && onClose()
  }
  const submitEntered = (e:any)=>{
    setBlocked(true)
    TicketService
    .MarkAsEntered(visit.ecommTicketsCodigo, visit.item, e.nota)
    .then(()=> _close())
    .finally(()=>setBlocked(false))
  }
  useEffect(() => {
    const handleEsc = (event:any) => {
       if (event.key === 'Escape') {
        _close();
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  return(
    
    <Sidebar blockScroll={true} dismissable={dismissable} visible={visible} position="right" onHide={() => _close()}  className="w-full md:w-3">
      <div id="VISITORS" className="">
        <h3>Visita: {visit?.visita}</h3>
        <div className="grid">
          <div className="field col-12">
            <ul className="list-none m-0 p-0 pr-0">
              <li className="pb-2"><span className="font-medium mr-1">Día:</span>{visit && i18n.date(new Date(visit?.fechaVisita))}</li>
              {!EVisistService.isVisitRange(visit?.visitaCodigo) &&<li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span>{visit?.horaVisita}</li>}
              {EVisistService.isVisitRange(visit?.visitaCodigo) &&<li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span><Trans>de {i18n.date(new Date(`01/01/2001 ${visit?.horaVisita}`), {timeStyle:"short"})} hasta {i18n.date(new Date(`01/01/2001 ${visit?.horaFinVisita}`), {timeStyle:"short"})} horas</Trans></li>}
              <li className="pb-2 flex align-content-evenly flex-wrap card-container orange-container"><span className="font-medium mr-1">Cant. de visitantes:</span> {cantidadVisitantes()}</li>
            </ul>
          </div>
         
        </div>
        <div className="grid">
            {
              
              visitants?.map((v:any)=>
                <React.Fragment key={v.tipoNacionalidadId}>
                  {nationalityTemplate(v)}
                  {
                    v.ages.map((a:any)=> <React.Fragment key={a.id}>{ageTemplate(a)}</React.Fragment>)
                  }
                </React.Fragment>
              )
            }
            
        </div>
      </div>
      {
      showMessage &&
      <Dialog visible={true} 
        onHide={() =>{  setShowMessage(null) }} 
        closeOnEscape={true} 
        position="top" 
        footer={
         showMessage.title === 'Ticket Usado' && (<div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(null) } /></div>)
        } 
        showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
       <div className="flex align-items-center flex-column pt-6 px-3">
           <i className="pi pi-exclamation-circle" style={{ fontSize: '5rem', color: `var(--${showMessage.color})` }}></i>
           <h5 className={`alert-check-in text-${showMessage.color}`}><Trans>{showMessage.title}</Trans></h5>
           <p className="alert-check-in-message">
            {showMessage.message}
           </p>
       </div>
       {showMessage.showForm && 
        <form onSubmit={handleSubmit(submitEntered)}>
        <div className="formgrid grid">
          <div className="field col-12">
            <Controller name="nota" control={control} render={({ field }) => (
              <InputTextarea id={field.name} {...field} style={{width:"100%"}} rows={5} />
            )} />
            {getFormErrorMessage("password")}
          </div>  
          <div className="field col-12">
            <Button className="font-semibold w-full" label={t`Registrar entrada`} />
          </div>
        </div>
        </form>
       }
       
      </Dialog>
    }
      <BlockSpinner uiBlocked={blocked} />
    </Sidebar>
  )
}
export { VisitDetail }