
import { Trans, t } from '@lingui/macro'
import { Button } from 'primereact/button'
import React, { useEffect, useState } from 'react'
import useCartMonaterio from '../../contexts/useCartMonaterio'
import { TicketService } from '../../services'
import { i18n } from "@lingui/core";
import { BlockSpinner } from '../../componentes'
import { useNavigate } from 'react-router-dom'
import { useLingui } from '@lingui/react';
export default function MyTickets() {
  useLingui()
  const navigate = useNavigate()
  const {  setHeaderPage } = useCartMonaterio()
  const [orders, setOrders] = useState([])
  const [blocked, setBlocked] = useState(false)
  useEffect(()=>{
    setHeaderPage({title:t`Mis Ordenes`, preview:"/"});
    setBlocked(true);
    
    TicketService
    .GetAllMyOrders()
    .then((r)=>setOrders(r.data))
    .finally(()=> setBlocked(false));

  },[])
  return (
    <div>
      <div className="container-rb pt-7 pb-4 surface-100">

      </div>
      <div className="container-rb pt-5">
        <div className="grid m-0">
        <div className="col-12 md:col-12 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0  md:border-bottom-none">
            
              <div className="col-12 pb-3 md:pb-4">
                <h3 className="my-0"><Trans>Ordenes</Trans><span className="font-normal ml-1"></span></h3>
              </div>
              {
                orders.map((o:any)=>
                <div key={o.codigo}  className="grid mt-0 mx-0 p-2 shadow-2 border-round-xl md:p-3">
                  <div className="col-12 py-0 flex align-items-center flex-wrap">
                      <div className="flex-1 align-items-center justify-content-center font-medium text-lg">#{o.codigo}</div>
                      <Button onClick={()=> {navigate(`/mytickets/${o.codigo}`)}} className="flex align-items-center justify-content-center" severity="secondary" icon="pi pi-arrow-right" rounded text aria-label="" />
                  </div>
                  
                  
                  <div className="col-12 md:col-3 md:pb-0">
                    <span className="font-medium mr-1"><Trans>Fecha</Trans>:</span> {i18n.date(new Date(o.fecha))}
                  </div>
                  <div className="col-12 md:col-3 md:pb-0">
                  <span className="font-medium mr-1"><Trans>Cantidad</Trans>:</span>{o.cantidad}
                  </div>
                  <div className="col-12 md:col-3 md:pb-0">
                  <span className="font-medium mr-1"><Trans>Comprobante</Trans>:</span>{o.tipoComprobante}
                  </div>
                  <div className="col-12 md:col-3 md:pb-0">
                    <span className="font-medium mr-1"><Trans>TOTAL</Trans>:</span> {i18n.number(o.total, { style: "currency", currency:"PEN" })} 
                  </div>
                  
                </div>
                )
              }
              
            
          </div>


        </div>
      </div>
      <BlockSpinner uiBlocked={blocked} />
    </div>
  )
}
