import { Trans } from "@lingui/macro"

const  CartOrderInfo =()=>{
return(
  <div className="col-12 md:col-4">
      <div className="grid mx-0 md:ml-4">
          <div className="col-12 md:pl-0">
              <h4 className="my-2 opacity-40"><Trans>Recuerda</Trans></h4>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>¡Sin colas! Dirígete directamente a la puerta de ingreso. No necesitas pasar por boletería.</Trans></p>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>Muestra tu orden de compra directamente desde tu celular, no es necesario imprimir.</Trans></p>
              {/*<h4 className="my-2 opacity-40"><Trans>Condiciones de compra</Trans></h4>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>¡Sin colas! Dirígete directamente a la sala o a la zona de despacho online en la Dulcería para recoger tu combo. No necesitas pasar por boletería.</Trans></p>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>Muestra tu orden de compra directamente desde tu celular, no es necesario imprimir.</Trans></p>
              <h4 className="my-2 opacity-40"><Trans>Estimado cliente</Trans></h4>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>Para un mejor servicio realiza los siguientes pasos:</Trans></p>
              <p className="mt-0 mb-2 w-full inline-block text-sm"><Trans>Muestra tu orden de compra directamente desde tu celular, no es necesario imprimir.</Trans></p>
              */}
          </div>
      </div>
  </div>
 )
}
export { CartOrderInfo }
