import React, { useEffect } from 'react'
import { stepItems } from '../StepItems';
import { Steps } from 'primereact/steps';
import { Trans, t } from "@lingui/macro";
import useCartMonaterio from '../../../contexts/useCartMonaterio';
import { CartOrderInfo } from '../../../componentes/cart-order-info';
import { useLingui } from '@lingui/react';

export default function CartOrderProcessError() {
  useLingui()
  const { setHeaderPage } = useCartMonaterio()

  useEffect(()=>{
    setHeaderPage({title:t`Carrito de compras`, preview:"/"});
  },[]);


  return (
    <div id="CHECKOUT-3-RB" >
      <div className="container-rb pt-7 pb-4 surface-100">
          <Steps model={stepItems}  activeIndex={1}  className="pt-7" />
      </div>
      <div className="container-rb pt-5">
          <div className="grid m-0">
              <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
                  <div className="grid mx-0 md:px-4">
                      <div className="col-12 pb-3 md:pb-2">
                          <h2 className="mt-0"><Trans>Falló el procesamiento de su Orden</Trans></h2>
                          <div className="text-lg"><Trans>Ha sucedido un error al momento de procesar su orden, por favor comuniquese con el administador del servicio</Trans></div>
                      </div>
                      
                  </div>
              </div>
              <CartOrderInfo />
          </div>
      </div>
      
    </div>
  )
}
