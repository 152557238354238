import axios from 'axios';
import urlBase from './Configuration'
//const urlBase = 'https://localhost:44324';
function resolveOrGetFormLocalStorage(key:any, fn:()=>Promise<any>){
    if(localStorage[key])
            return new Promise((r)=>{
                 const d = JSON.parse(localStorage[key])
                 r(d);
        })
        else{
            return new Promise((r,er)=>{
                fn()
                .then((res)=> {
                    localStorage[key]=JSON.stringify(res.data);
                    r(res.data);
                })
                .catch((error)=>er(error));
            })
        }
}
export const LocationService ={
    getDepartaments: function(){
        return resolveOrGetFormLocalStorage("departaments", ()=> axios.get(`${urlBase}/location/departaments`));
    },
    getProvince: function(departamentid:any){
      return axios.get(`${urlBase}/location/departaments/${departamentid}/provinces`);
    },
    getDistricts: function(departamentid:any, provinceid:any){
      return axios.get(`${urlBase}/location/departaments/${departamentid}/provinces/${provinceid}/districts`);
    },
    getCountries: function(){
        return resolveOrGetFormLocalStorage("countries", ()=> axios.get(`${urlBase}/location/countries`));
    }
}