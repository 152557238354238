import { useLingui } from "@lingui/react"
import { useEffect, useState } from "react"
import { PrimeIcons } from 'primereact/api'
export const stepItems = [
  {
      label: "Productos"
  },
  {
      label: "Pago"
  },
  {
      label: "Confirmación"
  }
]
const useSteps = (currentStep:any)=>{
  const { i18n } = useLingui()
  const [ steps, setSteps ]:any = useState([])
  
  useEffect(()=>{
    const s = stepItems.map((m,i)=>{
      return { label: i18n._(/*i18n*/ m.label), 
               className:currentStep > i? "p-highlight p-steps-current check-title":"",
               style: currentStep > i? {opacity:1}:undefined,
               
                 }
    });
    setSteps(s);
  },[i18n.locale])
  return { steps }
}
export { useSteps }
 