import React from 'react';
import './App.css';
import './theme-prime.css'
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.min.css';
import Header from './features/layout/Header'
import Footer from './features/layout/Footer'
import Home from './features/layout/Home'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import InPersonVisit from './features/visits/InPersonVisit';
import CartProducts         from './features/checkout/stepOne/CartProducts'
import CartCheckout         from './features/checkout/stepTwo/CartCheckout'
import CartOrderConfirmend  from './features/checkout/stepThree/CartOrderConfirmend'
import CartRejectedOrder    from './features/checkout/stepThree/CartRejectedOrder'
import { ReturnsPolitics } from './features/politics/returns-politic';
import { TermsPolitics } from './features/politics/terms-politics';
import CartOrderProcessError from './features/checkout/stepThree/CartOrderProcessError';
import CartOrderError from './features/checkout/stepThree/CartOrderError';
import RestorePasswordForm from './features/autentication/restore-password-form';
import MyTickets from './features/autorization/my-tickets';
import MyTicketDetail from './features/autorization/my-ticket-details';
import { VisitDetail } from './componentes/visit-details';
import { CheckTicket } from './features/check/check-ticket';
import { ViewVideo } from './features/autorization/view-video';
import { CartOrderPagoEfectivoCip } from './features/checkout/stepThree/CartOrderPagoEfectivoCip';

function App() {
  
  return (<Router>
      <div className="">
        <Header />
          <Routes>
            
            <Route path="/" element={<Home />}>
              <Route path='restorepassword' element={<RestorePasswordForm />}></Route>
              <Route path='returns' element={<ReturnsPolitics />}></Route>
              <Route path='terms' element={<TermsPolitics />}></Route>
            </Route>
            <Route path="/checkticket" element={<CheckTicket />} />
            <Route path="/mytickets" element={<MyTickets />} />
            <Route path="/mytickets/:ordernumber/:item/virtual" element={<ViewVideo />} />
            <Route path="/mytickets/:ordernumber" element={<MyTicketDetail />} >
              <Route path=":item" element={<VisitDetail componentRoute={true} />} />
            </Route>  
            <Route path="/visits/:id" element={<InPersonVisit />} />
            <Route path="/checkout/products" element={<CartProducts />} />
            <Route path="/checkout/checkout" element={<CartCheckout />}>
              <Route path='returns' element={<ReturnsPolitics />}></Route>
              <Route path='terms' element={<TermsPolitics />}></Route>
            </Route>
            <Route path="/checkout/pagoefectivo/:ordernumber" element={<CartOrderPagoEfectivoCip />} />
            <Route path="/checkout/confirmed/:ordernumber" element={<CartOrderConfirmend />} />
            <Route path="/checkout/refushed/:ordernumber" element={<CartRejectedOrder />} />
            <Route path="/checkout/error/:ordernumber" element={<CartOrderError />} />
            <Route path="/checkout/error" element={<CartOrderProcessError />} />
            
          </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
