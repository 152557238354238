const buildRequestOrder =(order:any)=>{
  const r = order;
  return {
    payMethod: r.payMethod,
    totalAmount: r.totalAmount,
    email: r.email,
    cuponDiscount: r.cuponDiscount && {
      id: r.cuponDiscount.codigo,
      codigo: r.cuponDiscount.codigo,
      descuento: r.cuponDiscount.descuento
    },
    invoiceData: r.invoiceData && {
      invoiceType: r.invoiceData.invoiceType,
      data: r.invoiceData.data && {
        ruc: r.invoiceData.data.ruc,
        businessName: r.invoiceData.data.businessName,
        // businessIndustry: r.invoiceData.data.businessIndustry,
        businessIndustry: '',
        address: r.invoiceData.data.address,
        // ubigeo: r.invoiceData.data.district.codigo,
        // contactName: r.invoiceData.data.contactName,
        // contactPhone: r.invoiceData.data.contactPhone,
        ubigeo: 0,
        contactName: '',
        contactPhone: '',
        email: r.invoiceData.data.email,
        // nota: r.invoiceData.data.nota
        nota: '',
      }
    },
    products: r.products.map((p:any)=>{
      
      let visit ={}
      if (!p.isVideo)
        visit ={
          itemFecha: p.timeScheduler.itemFecha,
          time: p.timeScheduler.time,
          endTime: p.timeScheduler.endTime,
          frecuencia: p.timeScheduler.frecuencia,
          dateScheduler: p.dateScheduler,
          departamentCode: p.departament && p.departament.codigo,
          countryCode: p.country && p.country.codigo,
          prices: p.prices.map((price:any)=>{
            return {
              typeNationality: price.typeNationality,
              totalCount: price.totalCount,
              priceAges:price.priceAges.map((pAge:any)=>{
                return {
                  ageType: pAge.ageType,
                  count: pAge.count,
                  item: pAge.item,
                  price: pAge.price,
                  discount: pAge.discount && {
                    item: pAge.discount.item,
                    descuento: pAge.discount.discount
                  }
                }
              })
            }
          })
        }
      
      return Object.assign(
        {
          id: p.id,
          amountTotal: p.amountTotal,
          isVirtual: !!p.isVideo,
        },visit);
    })
  }
}
export default buildRequestOrder;