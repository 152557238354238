import React, {useEffect, useState} from 'react'
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Trans, t } from "@lingui/macro";
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import useVisit from './useVisit';
import useCartMonasterio from './../../contexts/useCartMonaterio'
import * as z from 'zod';
import { useLingui } from '@lingui/react';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

const CODEForeign = 8
const CODENational = 7

const selectedCountryTemplate = (option: any, props: any) => {
  if (option) {
      return (
          <div className="flex align-items-center">
              <img alt={option.nombre} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.alpha2.toLowerCase()}`} style={{ width: '18px' }} />
              <div>{option.nombre}</div>
          </div>
      );
  }

  return <span>{props.placeholder}</span>;
};

const countryOptionTemplate = (option: any) => {
  return (
      <div className="flex align-items-center">
          <img alt={option.nombre} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.alpha2.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.nombre}</div>
      </div>
  );
};

const selectedTimeTemplate = (option: any, props: any, fnDate:any) => {
  if (option) {
      return (
          <div className="flex align-items-center">
            <div>
              {fnDate(new Date(`01/01/2001 ${option.time}`),{timeStyle:"short"})}
              {/*fnDate(new Date(`01/01/2001 ${option.endTime}`),{timeStyle:"short"})*/} 
              {option.quantityAvailable && <span className="p-error ml-2"> {option.quantityAvailable} <Trans>Disponibles</Trans></span> }
            </div>
          </div>
      );
  }

  return <span>{props.placeholder}</span>;
};

const timeOptionTemplate = (option: any, fnDate:any) => {
  return (
      <div className="flex align-items-center">
          <div>
            {fnDate(new Date(`01/01/2001 ${option.time}`),{timeStyle:"short"})}
            {/*fnDate(new Date(`01/01/2001 ${option.endTime}`),{timeStyle:"short"})*/} 
            {option.quantityAvailable && <span className="p-error ml-2"> {option.quantityAvailable} <Trans>Disponibles</Trans></span> }
          </div>
      </div>
  );
};

export  const InPersonVisitScheduler = ({id, visitData, _scheduler, departaments, countries, nameVisit, onSave}:any) => {

  const { i18n } = useLingui();
  const schema = z.object({
    visits: z.string().optional(),
    dateScheduler: z.date({required_error:t`Seleccione una fecha`}),
    timeScheduler: z.object({}, 
      {errorMap:(val:any, ctx:any)=>{ return { message: t`Seleccione una fecha` } }}).nonstrict(),
    departament:z.object({}).nonstrict().optional(),
    country: z.object({}).nonstrict().optional()
  }).superRefine((values, ctx)=>{
    
    var total = prices.filter((x:any)=>x.totalCount>0).reduce((a:any,c:any)=> a+c.totalCount, 0)
    if (values.timeScheduler.quantityAvailable !== undefined &&
        values.timeScheduler.quantityAvailable !== null &&
        total>values.timeScheduler.quantityAvailable){
        ctx.addIssue({
          message: t`* No existe suficientes entradas para este horario.`,
          code: z.ZodIssueCode.custom,
          path: ['timeScheduler'],
        });
    }

    if(!prices.find((x:any)=>x.totalCount>0)){
      ctx.addIssue({
        message: t`* Debe ingresar visitantes.`,
        code: z.ZodIssueCode.custom,
        path: ['visits'],
      });
    }
    else{
      if(prices.find((x:any)=>x.typeNationality === CODEForeign)?.totalCount > 0 && !values.country){
        ctx.addIssue({
          message: t`Seleccione un Pais.`,
          code: z.ZodIssueCode.custom,
          path: ['country'],
        });
      }
      if(prices.find((x:any)=>x.typeNationality === CODENational)?.totalCount > 0 && !values.departament){
        ctx.addIssue({
          message: t`Seleccione un departamento.`,
          code: z.ZodIssueCode.custom,
          path: ['departament'],
        });
      }
    }
  })
  const { products } = useCartMonasterio();
  
  const {visitTotal, resolveDiscount, excludedDates,  months,  scheduler, sessionsDay, prices, 
         viewDate, setViewDate, calculateTotals, callScheduler, updateSchedueler, filterSessions}:any = useVisit(id)

  const {control, formState: { errors }, handleSubmit, setValue, getValues }:any = useForm({resolver: zodResolver(schema)})

  const getFormErrorMessage = (name:any) => {
    
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  
  const onSumit = (data:any)=>{
    
    const fprices = prices
      .filter((x:any)=> x.totalCount > 0)
      .map((m:any)=>{
        return {

          typeNationality: m.typeNationality,
          totalCount: m.totalCount,
          priceAges: m.priceAges
                      .filter((p:any)=> p.count>0)
                      .map((p:any)=>{ p.discount = _resolveDiscount(p, m.typeNationality); return p; })
        }
      });
      data.dateScheduler =  new Date(Date.UTC(data.dateScheduler.getFullYear(), 
                        data.dateScheduler.getMonth(), 
                        data.dateScheduler.getDate(), 
                        data.dateScheduler.getHours(), 
                        data.dateScheduler.getMinutes(), 
                        data.dateScheduler.getSeconds()));
      
    if(onSave){
      onSave({...data, name:nameVisit, amountTotal: visitTotal, prices: fprices});
    }
  }

  const viewDateChange =(e:any)=>{
    const date = e.value
    const m = months[`${date.getFullYear()}${date.getMonth()}`]
    if (!m) callScheduler(date);
    else setViewDate(e.value);
  }
 
  const dateChange = (e:any, field:any)=>{
    field.onChange(e.value)
    setValue("timeScheduler", null)
    const sessionsDay = filterSessions(e.value);
    if (sessionsDay?.sessions?.length === 1)
      setValue("timeScheduler", sessionsDay.sessions[0])
    
  }
  const setDataForm = (currentProduct:any)=>{
    
    if (currentProduct){
      
      var dateScheduler = new Date(currentProduct.dateScheduler);
      dateScheduler = new Date(dateScheduler.getUTCFullYear(), 
      dateScheduler.getUTCMonth(), 
      dateScheduler.getUTCDate(), 
      dateScheduler.getUTCHours(), 
      dateScheduler.getUTCMinutes(), 
      dateScheduler.getUTCSeconds(), 
      dateScheduler.getUTCMilliseconds());

      setValue("dateScheduler", dateScheduler);
      setValue("departament", currentProduct.departament);
      setValue("timeScheduler", currentProduct.timeScheduler)
      setValue("country", currentProduct.country)
      filterSessions(dateScheduler);
      updatePrice(currentProduct);
    }
  }
  const updatePrice =(currentProduct:any)=>{
    
    if (currentProduct){
      _scheduler.prices.map((p:any)=>{
        p.priceAges.map((pa:any)=>{
          const cpPrice = currentProduct
                .prices.find((cp:any)=> cp.typeNationality === p.typeNationality);
          if (cpPrice){
            const cpPriceAge = cpPrice.priceAges.find((f:any)=>f.item===pa.item);
            pa.count = cpPriceAge ? cpPriceAge.count: 0
          }
          return pa;
        })
        return p;
      })
    }
  }
  useEffect(()=>{
    const currentProduct = products[id.toLocaleLowerCase()]
    updateSchedueler(_scheduler);
    if (currentProduct){
      setDataForm(currentProduct);
    }
    if (!prices || prices.length===0){
      _calculateTotals(_scheduler.prices);
    }
    
  },[_scheduler])

  const addAmmount=(e:any, priceAge:any, price:any)=>{
    const totalCount = prices.filter((x:any)=>x.totalCount>0)
                             .reduce((a:any,c:any)=> a + c.priceAges.reduce((pa:any,pc:any)=> price===c && priceAge===pc ? 0: pa + pc.count, 0)
                             , 0);
    const {timeScheduler} = getValues();
    if (  !timeScheduler 
      ||  !timeScheduler?.quantityAvailable 
      || totalCount + e.value <= timeScheduler.quantityAvailable){
      
      priceAge.count = e.value
      _calculateTotals(prices);
    }
  }
  const _calculateTotals = (prices:any)=>{
    
    const { dateScheduler } = getValues();
    
    calculateTotals(prices, dateScheduler);
  }
  const _resolveDiscount = (priceAge: any, typeNationality:any) =>
  {
    const { dateScheduler } = getValues();
    if (dateScheduler)
      return resolveDiscount(priceAge, typeNationality, dateScheduler)
    return undefined
  }
  const renderDiscount =(priceAge: any, typeNationality:any)=>{
    const discount = _resolveDiscount(priceAge, typeNationality);
      
    return (
      <span className="w-full inline-block text-sm opacity-80">
        { i18n.number(priceAge.price - (discount ? discount.discount:0), { style: "currency", currency:"PEN" })}
        &nbsp;
        {
            discount ?
            <span className='line-through'>{ i18n.number(priceAge.price, { style: "currency", currency:"PEN" })} </span>:
            <></>
        }
      </span>)
  }

  return (
    <div className="shadow-2 border-round-xl">
      <form onSubmit={handleSubmit(onSumit)}>
        <div className="formgrid grid mx-0 p-3 md:p-4">
            <div className="col-12 pb-4">
                <h3 className="mt-2 md:mt-0"><Trans>Completa la siguiente información para continuar con la compra</Trans></h3>
                <span className="font-semibold">
                  { sessionsDay?.sessions?.length === 1 ?
                   <Trans>Ingresa la fecha de tu visita</Trans>:
                   <Trans>Ingresa la fecha y hora para su visita</Trans>
                  }
                    
                </span>
            </div>
            <div className="field col-12">
              <span className="p-input-icon-left w-full">
                <i className="pi pi-building" />
                <InputText placeholder={t`Visita`} value={nameVisit} className="w-full" readOnly={true} />
              </span>
            </div>
            
            <div className="field col-12">
              <Controller name="dateScheduler" control={control}  render={({ field, fieldState }) =>(
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-calendar icon-position-prime" />
                  <Calendar 
                    locale={i18n.locale}
                    id={field.name}
                    {...field}
                    panelClassName='scheduler'
                    className="w-full"
                    inputClassName=' pl-5-5 w-full'
                    viewDate={viewDate} 
                    onViewDateChange={viewDateChange}
                    value={field.value}
                    onChange={(e)=>dateChange(e, field)}
                    minDate={scheduler.today} 
                    maxDate={scheduler.maxDate}
                    disabledDates={excludedDates}
                    readOnlyInput 
                    placeholder={t`Seleccione una fecha`}
                  />
                </span>
                
              )}/>
              {getFormErrorMessage("dateScheduler")}
              
                
            </div>
            <div className={classNames({"hidden md:hidden sm:hidden": sessionsDay?.sessions?.length <= 1, "field col-12":true})}>
              <Controller name="timeScheduler" control={control}  render={({ field }) =>(
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-clock icon-position-prime" />
                  <Dropdown 
                    
                    id={field.name}
                    {...field}
                    value={field.value} 
                    onChange={(e) => field.onChange(e.value)} 
                    options={sessionsDay.sessions} 
                    optionLabel="time"
                    filter 
                    valueTemplate={(o,p)=>selectedTimeTemplate(o, p, i18n.date.bind(i18n))} 
                    itemTemplate={(o)=>timeOptionTemplate(o, i18n.date.bind(i18n))} 
                    placeholder={t`Seleccione un horario`} className="pl-5 w-full"  />
                </span>
                
              )} />
              {getFormErrorMessage("timeScheduler")}
            </div>
            {
              sessionsDay?.sessions?.length === 1 &&
              <div className="field col-12">
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-building" />
                  <InputText placeholder={t`Horario Atención`} 
                    value={t`Horario de atención de ${i18n.date(new Date(`01/01/2001 ${sessionsDay?.sessions[0].time}`),{timeStyle:"short"})} a ${i18n.date(new Date(`01/01/2001 ${sessionsDay?.sessions[0].endTime}`),{timeStyle:"short"})}`} className="w-full" readOnly={true} />
                </span>
              </div>
            }
            
            <div className="col-12 pt-3">
              <span className="font-semibold"><Trans>Ingresa la cantidad de visitantes</Trans>
              </span>
              <br />
              
              {getFormErrorMessage("visits")}
            </div>
            
            {
              prices.map((price:any) =>
              <React.Fragment key={price.typeNationality}>
                  <div  className="col-12 py-4 flex">
                    <img alt="" className="mr-2" src={price.typeNationality===CODENational?"/icon-peru.svg":"/icon-extranjero.svg"} />
                    <span className="font-medium opacity-70">
                      {i18n._(/*i18n*/ price.name)}
                    </span>
                    <span className="opacity-70 ml-1">({price.totalCount})</span>
                  </div>
                  {price.priceAges.map((priceAge:any)=>
                    <React.Fragment key={`${price.typeNationality}-${priceAge.item}`}>
                      <div className="field col-5 md:col-6">
                        <span className="w-full inline-block font-semibold">{i18n._(/*i18n*/ priceAge.name)}</span>
                        <span className="w-full inline-block text-sm opacity-80">
                          {renderDiscount(priceAge, price.typeNationality)}
                        </span>
                      </div>
                      <div className="field col-7 md:col-6 flex">
                        <InputNumber  placeholder="0" className='flex-1' inputClassName="w-full text-center" showButtons buttonLayout="horizontal" 
                          step={1} max={99} min={0} value={priceAge.count} onValueChange={(e)=> addAmmount(e, priceAge, price)}
                          decrementButtonClassName="flex mr-2 p-button p-component p-button-icon-only p-button-text p-button-rounded border-circle" 
                          incrementButtonClassName="flex ml-2 p-button p-component p-button-icon-only p-button-text p-button-rounded border-circle" 
                          incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                      </div>
                    </React.Fragment>
                  )}
                  {
                    price.typeNationality===CODENational?
                    <div className="field col-12">
                      <div className="surface-100 border-round-lg p-3">
                          <span className="mb-2 w-full inline-block"><Trans>Seleccione tu ciudad de origen</Trans></span>
                            <Controller name="departament" control={control}  render={({ field, fieldState }) =>(
                              <span className="p-input-icon-left w-full">
                              <i className="pi pi-map-marker icon-position-prime" />
                                <Dropdown
                                  id={field.name}
                                  {...field}

                                  value={field.value} 
                                  onChange={(e)=> field.onChange(e.value)}
                                  options={departaments} 
                                  optionLabel="nombre" 
                                  placeholder={t`Seleccione un departamento`} 
                                  className="w-full pl-4" />
                              </span>
                          
                          )} />
                          {getFormErrorMessage("departament")}
                      </div>
                    </div>
                    :
                    <div className="field col-12">
                      <div className="surface-100 border-round-lg p-3">
                          <span className="mb-2 w-full inline-block"><Trans>Seleccione tu país de origen</Trans></span>
                          <Controller name="country" control={control}  render={({ field, fieldState }) =>(
                            <Dropdown  
                              id={field.name}
                              {...field}

                              value={field.value} 
                              onChange={(e)=> field.onChange(e.value)} 
                              options={countries} 
                              optionLabel="nombre" 
                              placeholder={t`Seleccione un país`}
                              filter 
                              valueTemplate={selectedCountryTemplate} 
                              itemTemplate={countryOptionTemplate} 
                              className="w-full" />
                          )} />
                          {getFormErrorMessage("country")}
                      </div>
                    </div>
                  }
                </React.Fragment>
              )
            }
            
        </div>
        <div className="grid mx-0 p-3 border-top-1 border-300 md:p-4">
            <div className="col-7 text-lg font-medium"><Trans>Importe a pagar</Trans></div>
            <div className="col-5 text-lg font-semibold text-right">{ i18n.number(visitTotal, { style: "currency", currency:"PEN" })}</div>
            <div className="col-12">
                <Button className="font-semibold w-full" icon="pi pi-cart-plus" label={t`Agregar al carrito`} />
            </div>
        </div>
      </form>
  </div>
  )
}


