import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import { Trans} from "@lingui/macro";

const ReturnsPolitics = ({onHide}:any)=>{
  const navigate =useNavigate()
  const hide = ()=>{
    if (onHide) onHide()
    else navigate('..')
  }
  return (
    <Sidebar blockScroll={true} dismissable={true} visible={true} position="right" onHide={() => hide()} className="w-full md:w-3">
      <div id="RETURNS" className="">
        <h3><Trans>Política de devoluciones</Trans></h3>
        <p className="text-justify"><Trans>Se efectuará devolución del pago, solamente en caso de que el visitante no haya podido usar su entrada y esté imposibilitado de regresar al monasterio dentro de un periodo de 11 meses contados a partir de la fecha de compra. La devolución se efectuará dentro de los plazos y parámetros que establecen las plataformas de pago.</Trans></p>
      </div>
    </Sidebar>)
}

export { ReturnsPolitics };