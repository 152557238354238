import { useCallback } from "react";
import { useAutorizationContext } from "./autorization-provider"

const useAuthenticationService = ()=>{
  const { accessToken, autenticate, logOff, login } = useAutorizationContext()
  

  const isAuthenticated = useCallback(()=> accessToken !== null, [accessToken])

  const isGuest = useCallback(()=> (isAuthenticated() && "guest" === (login || "").toLocaleLowerCase()), [accessToken, login])

  return {autenticate, logOff, isGuest, isAuthenticated, accessToken, login};
}
export { useAuthenticationService }