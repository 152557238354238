import { Sidebar } from 'primereact/sidebar';
import LoginForm from './login-form';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import UserRegister from './user-register';
import RecoverPassword from './recover-password';
const LoginControl = ({ urlAutecticated, nonRoute=false, showLogin=true, onClickRegister, onClose }:{urlAutecticated?:string, nonRoute?:boolean, showLogin?:boolean, onClickRegister?:any, onClose:any})=>{
  const [showRegister, setShowRegister] = useState(false);
  const [showRecoveryPassoword, setShowRecoveryPassoword] = useState(false);
  const [showSile, setShowSile] = useState(!nonRoute && showLogin)
  const navigate =useNavigate()

  const show =({registerUser, login, recovery}:any)=>{
    setShowRegister(registerUser)
    setShowRecoveryPassoword(recovery)
    setShowSile(login)
  }
  const onSuccess = ()=>{
    if (nonRoute) urlAutecticated ?navigate(urlAutecticated) :navigate("../")
    onClose && onClose()
    show({registerUser:false, login:false, recovery:false})
  }
  

  const close =()=>{
    if (!nonRoute) navigate("../")
    onClose && onClose()
    show({registerUser:false, login:false, recovery:false})
  }
  
  const ClickRegister = ()=>{
    show({registerUser:true, login:false, recovery:false})
    onClickRegister && onClickRegister();
  }
  const ClickRecoveryPassword= ()=>{
    show({registerUser:false, login:false, recovery:true})
  }
  const handleCloseRegisterUser = (e:any)=>{
    show({registerUser:false, login:true, recovery:false})
  }
  const handleCloseRecoveryPassword=()=>{
    show({registerUser:false, login:true, recovery:false})
  }
  
  useEffect(()=>{
    show({registerUser:false, login:showLogin, recovery:false})
  },[showLogin])
  
  return (
    <>
      <Sidebar blockScroll={true} dismissable={true} visible={showSile} position="right" onHide={() => close()} className="w-full md:w-3">
        <LoginForm 
          onSuccessfulLoginEvent={()=> onSuccess()} 
          onClickRegister={()=>ClickRegister()} 
          onRecoveryPassword={()=> ClickRecoveryPassword()}
        />
      </Sidebar>
      <UserRegister showRegister={showRegister} onClose={(e:any)=> handleCloseRegisterUser(e)} />
      <RecoverPassword showRecoveryPassword={showRecoveryPassoword} onClose={(e:any)=> handleCloseRecoveryPassword()}></RecoverPassword>
    </>
    
  )
}

export default LoginControl