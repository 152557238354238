import React, { useEffect, useState } from 'react'
import { TicketService } from '../../services';
import { useNavigate, useParams } from 'react-router-dom';
import { Trans, t } from "@lingui/macro";
import { BlockSpinner, CartOrderInfo, InfoOrder } from '../../componentes';
import useCartMonaterio from '../../contexts/useCartMonaterio';
import { VisitDetail } from '../../componentes';
import { useLingui } from '@lingui/react';

export default function MyTicketDetail() {
  const { i18n } = useLingui()
  const [selectedVisitor, setSelectedVisitor]:any = useState();

  const { ordernumber } = useParams()
  const [order, setOrder]:any = useState();
  const [blocked, setBlocked] = useState(false);
  const navigate = useNavigate()
  const { setHeaderPage } = useCartMonaterio()
  const download = (item:any)=>{
    TicketService
    .DownloadTicket(ordernumber, item, i18n.locale)
    .then(x=> {
      const href = window.URL.createObjectURL(x.data);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = `${ordernumber}-${item}`;

      document.body.appendChild(anchorElement);
      anchorElement.click();

      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    })
  }
  const viewVideo = (item:any)=>{
    navigate(`/mytickets/${ordernumber}/${item}/virtual`)
  }
  useEffect(()=>{
    setBlocked(true)
    TicketService
    .GetMyOrderConfirmed(ordernumber)
    .then((res)=> { setOrder(res.data); return res; })
    .then((res:any)=>{ setHeaderPage({title:`${t`Detalle de orden`} ${res?.data?.codigo}`, preview:"/"}); return res;})
    .then((res:any)=>{ !res.data ? navigate("/"):void(0)})
    .finally(()=>setBlocked(false));
    setHeaderPage({title:`${t`Detalle de orden`}`, preview:"/"});
    
  },[]);

  const openViewVisitor =  (v:any)=>{
    setSelectedVisitor(v.visita)
  }
  const closeViewVisitor =  ()=>{
    setSelectedVisitor(null)
  }


  return (
    <div id="CHECKOUT-3-RB" >
      <div className="container-rb pt-7 pb-4 surface-100">
          
      </div>
      <div className="container-rb pt-5">
          <div className="grid m-0">
              <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
                  <div className="grid mx-0 md:px-4">
                      <div className="col-12 pb-3 md:pb-2">
                          <h2 className="mt-0">Orden  #{order?.codigo}</h2>
                          <div className="text-lg"><Trans>Recibirás en tu bandeja de correo</Trans> <span className="font-medium">{order?.email}</span> un código QR con los detalles de tu compra, podrás ingresar mostrándolo en portería. ¡Gracias y que disfrutes de tu visita!</div>
                      </div>
                      <InfoOrder orderSelected={order} onViewVisitor={((v:any)=> openViewVisitor(v))} onDownload={download} onViewVideo={viewVideo} />
                  </div>
              </div>
              <CartOrderInfo />
          </div>
      </div>
      <BlockSpinner uiBlocked={blocked} />
      {selectedVisitor && <VisitDetail visitor={ selectedVisitor } onClose={()=>{ closeViewVisitor() }} /> } 
    </div>
  )
}
