import { Trans, t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { EVisistService } from './../services/EVisistService'
const InfoOrder =({orderSelected, showDownload= true, showViewVisitor=true, onDownload, onViewVideo, onViewVisitor}:any)=>{
  const [order, setOrder]:any = useState()

  useEffect(()=>{
    setOrder(orderSelected)
  },[orderSelected]) 

  const viewVideo = (item:any)=>{
    onViewVideo && onViewVideo(item)
  }
  const download = (item:any)=>{
    onDownload && onDownload(item);
  }
  const openViewVisitor = (v:any)=>{
    onViewVisitor && onViewVisitor({visita:v});
  }
  const renderVisit = (v:any)=>{
    
    const cantidadVisitantes = v.visitantes.reduce((a:any,c:any)=> a + c.cantidadVisitantes,0);
    const totalVisita = v.visitantes.reduce((a:any,c:any)=> a + c.cantidadVisitantes*c.precioUnitario,0);
    return (<div key={v.item} className="grid mt-0 mx-0 mb-2 py-2 border-bottom-1 border-400">
        <div className="col-4 pl-0 md:col-3 md:pb-0">
            <img alt="" className="w-full border-round-lg" src="/cover-visit-1.png" />
        </div>
        <div className="col-8 md:col-6 pb-0">
            <ul className="list-none m-0 p-0 pr-0">
                <li className="pb-2">
                    <h3 className="mt-0 mb-0">{v.Visita}</h3>
                </li>
                <li className="pb-2"><span className="font-medium mr-1"><Trans>Día</Trans>:</span>{i18n.date(new Date(v.fechaVisita))}</li>
                {!EVisistService.isVisitRange(v.visitaCodigo) &&<li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span>{v.horaVisita}</li>}
                {EVisistService.isVisitRange(v.visitaCodigo) &&<li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span><Trans>de {i18n.date(new Date(`01/01/2001 ${v.horaVisita}`), {timeStyle:"short"})} hasta {i18n.date(new Date(`01/01/2001 ${v.horaFinVisita}`), {timeStyle:"short"})} horas</Trans></li>}
                <li className="pb-2 flex align-content-evenly flex-wrap card-container orange-container">
                  <span className="font-medium mr-1">
                    <Trans>Cant. de visitantes</Trans>:
                  </span>{cantidadVisitantes}
                  {showDownload && <Button style={{"bottom":"11px"}} className="" icon="pi pi-download" rounded text  link onClick={() => { download(v.item)} } />}
                  {showViewVisitor && <Button style={{"bottom":"9px"}} className="p-button p-component font-bold p-button-link ml-2" label={t`Ver`} link onClick={() => { openViewVisitor(v)} } />}
                </li>
            </ul>
        </div>
        <div className="col-12 md:col-3 text-right pr-0">
            <span className="font-medium pt-1">{i18n.number(totalVisita, { style: "currency", currency:"PEN" })}</span>
        </div>
    </div>)
  }
  const renderVideo = (v:any)=>{
    return (
      <div key={v.item} className="grid mt-0 mx-0 mb-2 py-2 border-bottom-1 border-400">
          <div className="col-4 pl-0 md:col-3 md:pb-0">
              <img alt="" className="w-full border-round-lg" src="/home/vvirtual.png" />
          </div>
          <div className="col-8 md:col-6 pb-0 pr-0">
              <ul className="list-none m-0 p-0">
                  <li className="pb-2">
                      <h3 className="mt-0 mb-0"><Trans>Video</Trans></h3>
                  </li>
                  <li className="pb-2"><Trans>El paseo virtual contiene 7 capítulos sobre la fascinante historia del Monasterio de Santa Catalina. Una vez comprado el video, dispone de un acceso de 7 dias para su reproducción.</Trans></li>
                  <li className="pb-2 flex align-content-evenly flex-wrap card-container orange-container">
                    <span className="font-medium mr-1">
                      <Trans>Duración</Trans>:
                    </span><Trans>48 Minutos</Trans>
                    {onViewVideo && <Button style={{"bottom":"11px"}} className="" icon="pi pi-video" rounded text  link onClick={() => { viewVideo(v.item)} } />}
                  </li>
              </ul>
          </div>
          <div className="col-12 md:col-3 text-right pr-0">
              <span className="font-medium pt-1">{i18n.number(v.precioTotalVisita, { style: "currency", currency:"PEN" })}</span>
          </div>
      </div>
    )
  }

  return (
    <div className="col-12">
      <div className="surface-100 border-round-xl w-full py-3 p-2 mt-3 md:p-3">
          <div className="col-12 pt-1 pb-2">
              <span className="font-medium opacity-60"><Trans>Datos del cliente</Trans></span>
          </div>
          {order?.usuarioInfo ? 
            <div className="col-12">
              <h3 className="mt-0 mb-2">{order?.usuarioInfo?.nombres} {order?.usuarioInfo?.apPaterno} {order?.usuarioInfo?.apMaterno}</h3>
              <div className="py-1"><i className="pi pi-user mr-2"></i>{order?.usuarioInfo?.tipoDocumento} {order?.usuarioInfo?.docIdentidad}</div>
              <div className="py-1"><i className="pi pi-envelope mr-2"></i>{order?.usuarioInfo?.correoElectronico}</div>
              <div className="pt-1"><i className="pi pi-phone mr-2"></i>{order?.usuarioInfo?.numeroTelefono}</div>
          </div>
          :
          <div className="col-12">
            <div className="py-1"><i className="pi pi-envelope mr-2"></i>{order?.email}</div>
          </div>

          }
          
      </div>
      <div className="surface-100 border-round-xl w-full py-3 p-2 mt-3 md:p-3">
          <div className="col-12 pt-1 pb-2">
              <span className="font-medium opacity-60"><Trans>Productos</Trans> {order?.visitas?.length}</span>
          </div>
          <div className="col-12">
              <div className="grid mx-0">
                  <div className="col-12 p-0">
                      {
                        order?.visitas?.map((v:any)=>{return v.isVirtual ?  renderVideo(v) :renderVisit(v)})
                      }
                  </div>
                  <div className="col-12 pb-0 md:col-5 md:col-offset-7 px-0">
                      <div className="flex justify-content-between flex-wrap">
                          <div className="flex align-items-center justify-content-center"><Trans>Sub total</Trans></div>
                          <div className="flex align-items-center justify-content-center text-right">{i18n.number(order?.subTotal, { style: "currency", currency:"PEN" })}</div>
                      </div>
                      <div className="flex justify-content-between flex-wrap pt-2">
                          <div className="flex align-items-center justify-content-center"><Trans>Descuento</Trans></div>
                          <div className="flex align-items-center justify-content-center text-right">{i18n.number(order?.totalDescuento, { style: "currency", currency:"PEN" })}</div>
                      </div>
                      <div className="flex justify-content-between flex-wrap pt-2">
                          <div className="flex align-items-center justify-content-center">
                              <h3 className="mt-0 mb-0"><Trans>Total</Trans></h3>
                          </div>
                          <div className="flex align-items-center justify-content-center text-right">
                              <h3 className="mt-0 mb-0">{i18n.number(order?.total, { style: "currency", currency:"PEN" })}</h3>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {
        order?.paymentIzipay &&
        <div className="surface-100 border-round-xl w-full py-3 p-2 mt-3 md:p-3">
          <div className="col-12 pt-1 pb-2">
              <span className="font-medium opacity-60"><Trans>Datos de pago</Trans></span>
          </div>
          <div className="col-12">
              <div className="grid mx-0">
                  <div className="col-3 md:col-1 pl-0">
                      <img alt="" className="w-full border-round-md" src="/logo-visa.png" />
                  </div>
                  <div className="col-9 md:col-11 pb-0">
                      <h3 className="mt-0 mb-2">{order.paymentIzipay.pan}</h3>
                      <div className="py-1">{order.paymentIzipay.effectiveBrand}</div>
                      <div className="pt-1"><span className="font-medium mr-1">ID operación:</span>#</div>
                  </div>

              </div>
          </div>
      </div>
      }
      
      {
        !order?.datosFacturacion  ||
        <div className="surface-100 border-round-xl w-full py-3 p-2 mt-3 md:p-3">
          <div className="col-12 pt-1 pb-2">
              <span className="font-medium opacity-60">Datos de facturación</span>
          </div>
          <div className="col-12">
              <h3 className="mt-0 mb-2">{order?.datosFacturacion?.razonSocial}</h3>
              <div className="py-1">RUC {order?.datosFacturacion?.docIdentidad}</div>
              <div className="py-1">{order?.datosFacturacion?.direccion}</div>
              <div className="pt-1">{order?.datosFacturacion?.distrito} {order?.datosFacturacion?.provincia} {order?.datosFacturacion?.departamento}</div>
          </div>
        </div>
      }
      
  </div>
  )
}

export { InfoOrder }