import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import useCartMonaterio from './../../../contexts/useCartMonaterio'
import { videoID } from './../../../contexts/useCartMonasterioProducts'
import { getVisit, EVisistService } from './../../../services/EVisistService'
import { i18n } from "@lingui/core";
import { useNavigate } from 'react-router-dom';

const ViewItemsAdded =()=> {
  const navigate = useNavigate()
  const [ visitVideo, setVisitVideo ]:any = useState()
  const { addVideo, isAppendVideo, products, removeProduct } = useCartMonaterio()
  useEffect(()=>{
    getVisit(videoID)
    .then(r=>{
      setVisitVideo(r);
    })
  },[])
  return (
    <div className="col-12">
      {
       Object.values(products||[])
       .filter((p:any)=>p.id?.toLocaleLowerCase() !== videoID.toLocaleLowerCase())
       .map((prod:any)=>(
        <div key={prod.id} className="grid mt-0 mx-0 p-2 shadow-2 border-round-xl md:p-3">
          <div className="col-12 py-0 flex align-items-center flex-wrap">
              <div className="flex-1 align-items-center justify-content-center font-medium text-lg"><Trans>{prod.name}</Trans></div>
              <Button onClick={()=> removeProduct(prod.id)} className="flex align-items-center justify-content-center" severity="secondary" icon="pi pi-trash" rounded text aria-label="" />
          </div>
          <div className="col-4 md:col-3 md:pb-0">
              <img alt="" className="w-full border-round-lg" src="/cover-visit-1.png" />
          </div>
          <div className="col-8">
              <ul className="list-none m-0 p-0">
                  <li className="pb-2"><span className="font-medium mr-1"><Trans>Día</Trans>:</span>{i18n.date(prod.dateScheduler, { timeZone: 'UTC' })}</li>
                  {!EVisistService.isVisitRange(prod.id) && <li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span>{prod?.timeScheduler?.time}</li>}
                  {EVisistService.isVisitRange(prod.id) && <li className="pb-2"><span className="font-medium mr-1"><Trans>Hora</Trans>:</span><Trans>de {i18n.date(new Date(`01/01/2001 ${prod?.timeScheduler?.time}`), {timeStyle:"short"})} hasta {i18n.date(new Date(`01/01/2001 ${prod?.timeScheduler?.endTime}`), {timeStyle:"short"})} horas</Trans></li>}
                  
                  <li className="pb-2"><span className="font-medium mr-1"><Trans>Cant. de visitantes</Trans>:</span>
                  {prod.prices.reduce((a:any,e:any)=> a + e.totalCount, 0)}
                  </li>
                  <li className="flex align-items-center flex-wrap">
                      <Button onClick={()=> navigate(`/visits/${prod.id}`)} className="flex align-items-center justify-content-center font-bold" label={t`editar`} link />
                      <span className="flex-1 align-items-center justify-content-center text-right font-bold pt-1">{i18n.number(prod.amountTotal, { style: "currency", currency:"PEN" })}</span>
                  </li>
              </ul>
          </div>
        </div>
       ))
      }
      
      {
        isAppendVideo ||
        <div className="grid mt-4 mx-0 p-2 border-round-xl bg-red-16 md:p-3">
          <div className="col-12 py-0 flex align-items-center flex-wrap">
              <div className="flex-1 align-items-center justify-content-center font-medium text-lg"><Trans>¿Deseas agregar el video?</Trans></div>
              {/*<Button className="flex align-items-center justify-content-center" severity="secondary" icon="pi pi-times" rounded text aria-label="" />*/}
          </div>
          <div className="col-4 md:col-3 md:pb-0">
              <img alt="" className="w-full border-round-lg" src="/home/vvirtual.png" />
          </div>
          <div className="col-8">
              <ul className="list-none m-0 p-0">
                  <li className="pb-2"><Trans>El paseo virtual contiene 7 capítulos sobre la fascinante historia del Monasterio de Santa Catalina. Una vez comprado el video, dispone de un acceso de 7 dias para su reproducción.</Trans></li>
                  <li className="flex align-items-center flex-wrap pt-2">
                      <span className="flex align-items-center justify-content-center font-bold mr-3"> { i18n.number(visitVideo?.precioBase, { style: "currency", currency:"PEN" }) }</span>
                      <Button onClick={()=> addVideo() } className="flex align-items-center justify-content-center font-semibold" label={t`Agregar`} outlined />
                  </li>
              </ul>
          </div>
        </div>
      }
      {
        !isAppendVideo ||
        <div className="grid mt-2 mx-0 p-2 shadow-2 border-round-xl md:p-3">
          <div className="col-12 py-0 flex align-items-center flex-wrap">
              <div className="flex-1 align-items-center justify-content-center font-medium text-lg"><Trans>Video-tour</Trans></div>
              <Button onClick={()=> removeProduct(videoID)} className="flex align-items-center justify-content-center" severity="secondary" icon="pi pi-trash" rounded text aria-label="" />
          </div>
          <div className="col-4 md:col-3 md:pb-0">
              <img alt="" className="w-full border-round-lg" src="/home/vvirtual.png" />
          </div>
          <div className="col-8">
              <ul className="list-none m-0 p-0">
                  <li className="pb-2"><span className="font-medium mr-1"><Trans>Duración</Trans>:</span>48 <Trans>mins</Trans></li>
                  <li className="pb-2"><Trans>El paseo virtual contiene 7 capítulos sobre la fascinante historia del Monasterio de Santa Catalina. Una vez comprado el video, dispone de un acceso de 7 dias para su reproducción.</Trans></li>
                  <li className="flex align-items-center flex-wrap">
                      <span className="flex-1 align-items-center justify-content-center text-right font-bold pt-1"> { i18n.number(products[videoID.toLocaleLowerCase()]?.amountTotal, { style: "currency", currency:"PEN" }) } </span>
                  </li>
              </ul>
          </div>
        </div>
      }
      
  </div>
  )
}
export default ViewItemsAdded;