import axios from 'axios';
import urlBase from './Configuration'
import { getHeaders } from './TokenHeader';

class AuthenticatedUser {
  public static changePassword(request:any): Promise<any>{
    return axios.post(`${urlBase}/user/reset/password`, request, {headers: getHeaders()});
  }

}
export { AuthenticatedUser }