import React from 'react'
import { Button } from 'primereact/button';
import { Link } from "react-router-dom";

export default function ProductTypeVisit({className="itemVisit shadow-2 cursor-pointer", caption, srcImage, id}:any) {
  return (
    <div className={className}>
      <Link  to={`/visits/${id}`} >
          <div className="topItemVisit flex align-items-center">
              <img alt="" className="" src={srcImage} />
          </div>
          <div className="bottomItemVisit flex align-items-center p-3">
              <h3 className="flex-1 my-0">{caption}</h3>
              
                <Button className="flex" icon="pi pi-arrow-right" rounded text aria-label="" />
              
          </div>
        </Link>
    </div>
  )
}
