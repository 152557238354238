import React, { useState } from 'react'
import { Password } from 'primereact/password';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import { Controller, useForm } from 'react-hook-form';
import { LoginService } from '../../services';
import { useAuthenticationService } from '../../autorization'
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { BlockSpinner } from '../block-spinner';

export default function LoginForm({ onSuccessfulLoginEvent, onClickRegister, onRecoveryPassword }:any) {
  const schema = z.object({
    username:z.string().email({message:t`Debe ingresar un correo electrónico valido.`}).min(3).max(200),
    password:z.string().min(1, t`Debe de ingresar un password`).max(200)
  });
  const { autenticate } = useAuthenticationService()
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [blocked , setBlocked] = useState(false);
  const { handleSubmit, control, formState: { errors }, setValue }:any = useForm({ resolver:zodResolver(schema), defaultValues:{username:"", password:""}});
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const onSubmit = (e:any)=>{
    
    setBlocked(true)
    LoginService
    .autenticate(e.username, e.password)
    .then((r:any)=> autenticate(r.data, e.username))
    .then(()=> onSuccessfulLoginEvent && onSuccessfulLoginEvent())
    .catch(()=>{
      setValue("password", "");
      setErrorMessage(t({message:`Usuario o password invalido`}))
    })
    .finally(()=> setBlocked(false))
    
  }
  const openRegister = ()=>{
    onClickRegister && onClickRegister();
  }
  const openRecoveryPassword =()=>{
    onRecoveryPassword && onRecoveryPassword();
  }
  const loginAsGuest = ()=>{
    onSubmit({username:"guest", password:"guest"});
  }
  return (
    <div id="SING-IN" className="">
      
      <h3><Trans>Que bueno tenerte por aquí!</Trans></h3>
      
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formgrid grid">
            <div className="field col-12">
                <span className="font-medium opacity-70"><Trans>Inicia sesión para continuar con tu compra.</Trans></span>
            </div>
            
            <div className="field col-12">
              <Controller name="username" control={control} render={({ field }) => (
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-user" />
                  <InputText id={field.name} {...field} minLength={5} maxLength={200} placeholder={t`Correo electrónico`} className="w-full" />
                </span>
              )} />
              {getFormErrorMessage("username")}
            </div>
            <div className="field col-12">
              <Controller name="password" control={control} render={({ field }) => (
                <span className="p-input-icon-left w-full">
                  <i className="pi pi-lock icon-position-prime" />
                  <Password id={field.name} {...field} minLength={1} maxLength={200} feedback={false} value={field.value} onChange={(e) => field.onChange(e.target.value)} toggleMask className="w-full" inputClassName="w-full pl-5-5" />
                </span>
              )} />
              {getFormErrorMessage("password")}
              {
                errorMessage  !== null &&
                <small className="p-error">{errorMessage}</small>
              }
            </div>
            <div className="field col-12">
            
                <Button className="font-semibold w-full" label={t`Ingresar`} />
            </div>
            <div className="field col-12 flex justify-content-between flex-wrap mt-2">
              
                <Button onClick={()=>openRecoveryPassword()} type='button' className="flex align-items-center justify-content-center font-bold" severity="secondary" label={t`Olvide mi contraseña`} link />
                <Button onClick={()=>openRegister()} type='button' className="flex align-items-center justify-content-center font-bold" label={t`Crear cuenta`} link />
            </div>
            <div className="field col-12 border-top-1 border-300 pt-4 mt-2">
                <Button onClick={()=>{ loginAsGuest() }} type='button' className="font-semibold w-full" severity="secondary" label={t`Continuar como invitado`} outlined />
            </div>
        </div>
      </form>
      <BlockSpinner uiBlocked={blocked} />
    </div>
  )
}
