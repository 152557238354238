import { useCartMonasterioContext } from "./CartMonasterioProvider"
import useCartMonasterioTotal from "./useCartMonasterioTotal";
import { getVisit } from './../services/EVisistService'
import { useEffect, useState } from "react";

export const videoID:any = '7f11d28a-3251-4731-bacb-73e7cb986166'
const useCartMonasterioProducts = () => {
    
    const [ isAppendVideo, setIsAppendVideo ] = useState(false);
    const { products, setProducts } = useCartMonasterioContext();
    const { updateCartTotal } = useCartMonasterioTotal();
    useEffect(()=>{
      setIsAppendVideo(!!products[videoID.toLocaleLowerCase()]);
    }, [])
    
    const updateStates = (prods:any)=>{
      setProducts(prods)
      updateCartTotal(prods);
    }
    const addProduct = (id:string, newProduct: any) => {
      const p:any = {}//products || {}
      p[id.toLowerCase()] = newProduct
      if (isAppendVideo)
        p[videoID.toLocaleLowerCase()] =products[videoID.toLocaleLowerCase()]
      updateStates(p)
    }
    const removeProduct = (id: any) => {
      if(id.toLowerCase() === videoID.toLocaleLowerCase())
        setIsAppendVideo(false)
      delete products[id.toLowerCase()]
      updateStates({...products})
    }
    
    const addVideo = ()=>{
      getVisit(videoID)
        .then((r)=>{
          const p:any = products || {}
          const video = {id: videoID, amountTotal: r.precioBase, isVideo:true}
          p[videoID.toLocaleLowerCase()] = video;
          setIsAppendVideo(true)
          updateStates(p)
        });
    }

    const clearProducts =()=>{
      setProducts({})
      setIsAppendVideo(false)
      updateCartTotal({});
    }
    const formatString = (format:any, args:any[])=> {
      return format.replace(/{(\d+)}/g, (match:any, index:any) => {
        return typeof args[index] !== 'undefined' ? args[index] : match;
      });
    }

    const getHourText =(product:any, locale:any)=>{
      const format = product?.formatShowHour[locale];
      if (!format){
        return product.timeScheduler?.time
      }
      else
        return formatString(format, [product.timeScheduler?.time, product.timeScheduler?.endTime])
    }
    return { products, addProduct, removeProduct, addVideo, isAppendVideo,clearProducts, getHourText }
}

export default useCartMonasterioProducts;