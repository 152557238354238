import React, {useEffect, useState } from 'react'
import { PhotoService } from './../../services/PhotoService'
import { useParams } from 'react-router-dom';
import { EVisistService } from '../../services/EVisistService';
import { LocationService } from '../../services/LocationService';
import { InPersonVisitInfo } from './InPersonVisitInfo';
import { InPersonVisitScheduler } from './InPersonVisitScheduler';
import useCartMonasterio from './../../contexts/useCartMonaterio'
import { useNavigate } from "react-router-dom";
import { BlockSpinner } from '../../componentes';
import { useLingui } from '@lingui/react';

export default function InPersonVisit() {
  useLingui()
  const navigate = useNavigate()
  const { id }:any = useParams();
  const [blocked, setBlocked] = useState(false);
  const [images, setImages] = useState([]);
  const [visit , setVisit] = useState(null);
  const [nameVisit , setNameVisit] = useState("");
  const [scheduler, setScheduler]:any = useState({today:new Date(), maxDate:new Date(), months:[], prices:[]});
  const [departaments, setDepartaments]:any = useState([]);
  const [countries, setCountries]:any = useState([]);
  const { addProduct, products, setHeaderPage } = useCartMonasterio()

  useEffect(() => {
    const currentProduct = products[id.toLocaleLowerCase()]
    const fn = ()=>{
      if (currentProduct){
        const dateScheduler = new Date(currentProduct.dateScheduler);
        return EVisistService.getSheduler(id, dateScheduler.getFullYear(), dateScheduler.getMonth() + 1, 4)
      }else{
        return EVisistService.getTodaySheduler(id)  
      }
    }
    setBlocked(true);
    Promise
    .all([PhotoService.getImages(id), 
    EVisistService.getVisit(id),
    fn(), //EVisistService.getTodaySheduler(id)
    LocationService.getCountries(),
    LocationService.getDepartaments()])
    .then(([imgs, _visit, _scheduler, _countries, _departaments])=>{
      const name = _visit?.data?.nombre;
      setNameVisit(name);
      setHeaderPage({title:name, preview:"/"});  
      setImages(imgs as any);
      setVisit(_visit.data);
      setScheduler(_scheduler.data)
      setCountries(_countries)
      setDepartaments(_departaments)
    })
    .finally(()=> setBlocked(false));
    
    
  }, [])
  const handleSave = (data:any)=>{
    
    addProduct(id, {id, ...data});
    navigate("/checkout/products")
  }
  return (
    <div id="DETAIL-RB" className="">
      
      <div className="container-rb pt-7">
        <div className="grid m-0 pt-7">
          <div className="col-12 md:col-7 md:pr-4">
            {!visit || <InPersonVisitInfo  visitData={visit} />}
          </div>
          <div className="col-12 md:col-5">
            <InPersonVisitScheduler 
              id={id} 
              visitData = {visit}
              nameVisit={nameVisit}
              _scheduler={scheduler} 
              departaments={departaments} 
              countries={countries}
              onSave={handleSave} />
              
          </div>
        </div>
      </div>
      <BlockSpinner uiBlocked={blocked} />
    </div>
  )
}
