import React, { useEffect, useRef, useState } from 'react'
import { Sidebar } from 'primereact/sidebar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from "primereact/inputtext";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Trans, t } from "@lingui/macro";
import { useForm, Controller } from 'react-hook-form';
import { CategoryService, LocationService } from "../../services";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Password } from 'primereact/password';
import { UserService } from '../../services'
import { Toast } from 'primereact/toast';
import { BlockSpinner } from '../block-spinner'

const selectedCountryTemplate = (option: any, props: any) => {
  if (option) {
      return (
          <div className="flex align-items-center">
              <img alt={option.nombre} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.alpha2.toLowerCase()}`} style={{ width: '18px' }} />
              <div>{option.nombre}</div>
          </div>
      );
  }

  return <span>{props.placeholder}</span>;
};

const countryOptionTemplate = (option: any) => {
  return (
      <div className="flex align-items-center">
          <img alt={option.nombre} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.alpha2.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.nombre}</div>
      </div>
  );
};


export default function UserRegister({ onClose, showRegister=false }:{onClose?:any, showRegister?:boolean}) {
  const toast:any = useRef(null);
  const [showSile, setShowSile] = useState(showRegister)
  const [blocked, setBlocked] = useState(false);
  const [countries, setCountries]:any = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [documentTypes, setDocumentTypes]:any = useState([]);
  const [formData, setFormData]:any = useState()

  const defaultValues={
    name: "",
    lastName: "",
    lastNameMother: "",
    documentNumber: "",
    phone: "",
    email: "",
    password: "",
    passwordRepeat: ""
  }
  const schema = z.object({
    country:z.object({},{ required_error:t`Seleccione un pais` }).nonstrict(),
    name:z.string().regex(/^[A-Za-z? 'ñÑ,_-]{3,100}$/, t`Ingrese su nombre`),
    lastName:z.string().regex(/^[A-Za-z? 'ñÑ,_-]{3,100}$/, t`Ingrese ap. paterno`),
    lastNameMother:z.string().regex(/^[A-Za-z? 'ñÑ,_-]{3,100}$/, t`Ingrese ap. materno`),
    documentType: z.object({}, { required_error:t`Seleccione un tipo documento` }).nonstrict(),
    documentNumber: z.string().regex(/^([0-9]){6,20}$/, { message:t`Ingrese un numero de documento` }),
    phone: z.string().regex(/^(\+?[0-9]){6,20}$/, { message:t`Ingrese un numero de telefonico valido` }),
    email: z.string({required_error:t`Ingrese un email`}).email({message:t`Ingrese un email`}),
    password: z.string().regex(/^(.){5,200}$/, t`La contraseña debe ser minimo de 5 caracteres alphanumerico`),
    passwordRepeat: z.string()
  }).superRefine((values,c)=>{
    values.password === values.passwordRepeat ||
    c.addIssue({
      message: t({message:'La contraseña confirmada debe ser igual a la contraseña ingresada'}),
      code: z.ZodIssueCode.custom,
      path: ['passwordRepeat'],
    });
  });
  const handleClose = (showLogin=false)=>{
    onClose && onClose({ showLogin })
    setShowSile(false);
  }
  const { handleSubmit, control, formState: { errors } }:any = useForm({resolver:zodResolver(schema), defaultValues})
  const dialogFooter = <div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => handleClose()} /></div>;
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  useEffect(()=> setShowSile(showRegister),[showRegister]);

  useEffect(()=>{
    Promise
    .all([CategoryService.getCategories(5), LocationService.getCountries()])
    .then(([_categories, _countries]:any)=>{
      setCountries(_countries)
      setDocumentTypes(_categories.data);
    })
  }
  ,[])
  const onSubmit = (e:any)=>{
    
    const v = e;
    setFormData(v);
    const request = {
      usuario: v.email,
      password: v.password,
      nombre: v.name,
      apPaterno: v.lastName,
      apMaterno: v.lastNameMother,
      numeroDocumento: v.documentNumber,
      tipoDocumento: v.documentType && v.documentType.codigo,
      phoneNumber: v.phone,
      paisCodigo: v.country && v.country.codigo,
    }
    const responseHTTP200 = (e:any)=>{
      const response = e.data
      if (!response.success){
        toast.current.show({severity:'error', 
                            summary: t`Registro de usuario`, 
                            detail:response.messageError, 
                            life: 3000});
      }
      else{
        setShowMessage(true);
      }
    }
    setBlocked(true);
    UserService
    .getRegisterNewUser(request)
    .then((e)=> responseHTTP200(e))
    .catch(()=> toast.current.show({severity:'error', summary: t`Registro de usuario`, detail:t`Ha ocurrido un error, intentelo mas tarde`, life: 3000}))
    .finally(()=> setBlocked(false));
  }
  return (
    <Sidebar blockScroll={true}  dismissable={true} visible={showSile} position="right" onHide={() => handleClose()} className="w-full md:w-3">
      <Dialog visible={showMessage} onHide={() => setShowMessage(false)} position="top" footer={dialogFooter} showHeader={false} breakpoints={{ '960px': '80vw' }} className='messageConfirmation'>
        <div className="flex justify-content-center flex-column pt-6 px-3">
            <i className="pi pi-check-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
            <h5>Registro exitosa!</h5>
            <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
                <Trans>Su cuenta está registrada a nombre de</Trans> <b>{formData?.name}</b> ; <Trans>Tenga en cuenta que podrá acceder al portal de compra de entradas utilizando la dirección de correo registrada</Trans>&nbsp;<b>{formData?.email}</b>.
            </p>
        </div>
      </Dialog>
      <div id="SING-UP" className="">
          <h3><Trans>Crear cuenta</Trans></h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formgrid grid">
              <div className="field col-12">
                  <span className="font-medium opacity-70"><Trans>Completa la siguiente información</Trans></span>
              </div>
              <div className="field col-12">
                <Controller name="country" control={control} render={({ field }) => (
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-flag icon-position-prime" />
                    <Dropdown id={field.name}  {...field} autoFocus 
                      value={field.value} onChange={(e)=>{ field.onChange(e.target.value) }}
                      options={countries} optionLabel="nombre" placeholder={t`País`}
                      filter valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate} 
                      className="w-full pl-4" />
                  </span>
                )} />
                {getFormErrorMessage("country")}  
              </div>
              <div className="field col-12">
                <Controller name="name" control={control} render={({ field }) => (
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-user-edit" />
                      <InputText id={field.name} {...field} minLength={3} maxLength={100} placeholder={t`Nombres`} className="w-full" />
                  </span>
                )} />
                {getFormErrorMessage("name")}
                  
              </div>
              <div className="field col-12 md:col-6">
                <Controller name="lastName" control={control} render={({ field }) => (
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-user-edit" />
                    <InputText id={field.name} {...field} minLength={3} maxLength={100} placeholder={t`Ap. Paterno`} className="w-full" />
                  </span>
                )} />
                {getFormErrorMessage("lastName")}
                  
              </div>
              <div className="field col-12 md:col-6">
                  <Controller name="lastNameMother" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-user-edit" />
                      <InputText id={field.name} {...field} minLength={3} maxLength={100} placeholder={t`Ap. Materno`} className="w-full" />
                    </span>
                  )} />
                  {getFormErrorMessage("lastNameMother")}
              </div>
              <div className="field col-12">
                <Controller name="documentType" control={control} render={({ field }) => (
                  <span className="p-input-icon-left w-full">
                    <i className="pi pi-id-card icon-position-prime" />
                    <Dropdown id={field.name} {...field} autoFocus value={field.value} onChange={(e)=>{ field.onChange(e.target.value) }}
                    options={documentTypes} optionLabel="nombre" placeholder={t`Tipo de documento`}
                    filter className="w-full pl-4" />
                  </span>
                )} />
                {getFormErrorMessage("documentType")}  
                  
              </div>
              <div className="field col-12">
                  <Controller name="documentNumber" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-id-card" />
                      <InputText id={field.name} {...field} minLength={5} maxLength={25} placeholder={t`Nro. de documento`}  className="w-full" />
                    </span>
                  )} />
                  {getFormErrorMessage("documentNumber")}
                  
              </div>
              <div className="field col-12">
                  <Controller name="phone" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-phone" />
                      <InputText id={field.name} {...field} minLength={6} maxLength={20} placeholder={t`Teléfono`} className="w-full" />
                    </span>
                  )} />
                  {getFormErrorMessage("phone")}
                  
              </div>
              <div className="field col-12">
                <Controller name="email" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-envelope" />
                      <InputText id={field.name} {...field} minLength={5} maxLength={100} placeholder={t`Correo electrónico`} className="w-full" />
                    </span>
                  )} />
                  {getFormErrorMessage("email")}
                  
              </div>
              <div className="field col-12">
                <Controller name="password" control={control} render={({ field }) => (
                  <span className="p-input-icon-left w-full">
                      <i className="pi pi-lock icon-position-prime" />
                      <Password id={field.name} {...field} feedback={false}  toggleMask minLength={4}  maxLength={25} placeholder={t`Contraseña`} className="w-full" inputClassName="w-full pl-5-5"  />
                  </span>
                )} />
                {getFormErrorMessage("password")}
                  
              </div>
              <div className="field col-12">
                  
                  <Controller name="passwordRepeat" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                      <i className="pi pi-lock icon-position-prime" />
                      <Password id={field.name} {...field} feedback={false} toggleMask minLength={4} maxLength={25} placeholder={t`Confirmar contraseña`}  className="w-full" inputClassName="w-full pl-5-5"  />
                    </span>
                  )} />
                  {getFormErrorMessage("passwordRepeat")}
              </div>
              <div className="field col-12">
                  <Button disabled={blocked} className="font-semibold w-full" label={t`Registrarme`} />
              </div>
              <div className="field col-12 border-top-1 border-300 pt-4 mt-2">
                  <Button type='button' onClick={()=>handleClose(true)} className="font-semibold w-full" severity="secondary" label={t`Iniciar sesión`}  outlined />
              </div>
            </div>
          </form>
          
      </div>
      <Toast ref={toast} />
      <BlockSpinner uiBlocked={blocked} />
    </Sidebar>
  )
}
