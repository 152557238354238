import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Badge } from 'primereact/badge';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';
import { langs, detectedLocale, defaultLocale, dynamicActivate } from './../../i18n'
import useCartMonasterio from './../../contexts/useCartMonaterio';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuthenticationService }  from '../../autorization/useAuthenticationService'
import { LoginControl } from '../../componentes';
import ChangePasswordForm from '../autorization/change-password-form';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

const selectedLocaleTemplate = (option:any, props:any) => {
  if (option) {
      return (
          <div className="flex align-items-center">
              <img alt={option.name} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
              <div>{option.name}</div>
          </div>
      );
  }

  return <span>{props.placeholder}</span>;
};
const localeOptionTemplate = (option: any) => {
  return (
      <div className="flex align-items-center">
          <img alt={option.name} src="/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
      </div>
  );
};

function Header() {
    useLingui()
    const location = useLocation();
    
    const [showLogin, setShowLogin] = useState(false);
    const [showChangePassword, setShowChangePassword] = useState(false);
    
    const { isAuthenticated, isGuest, logOff } = useAuthenticationService()
    const navigation = useNavigate()
    const menuAuthenticated:any = useRef<Menu>(null);
    const _locale = langs[detectedLocale] ? langs[detectedLocale] : langs[defaultLocale];
    const locales = Object.values(langs);
    const [selectedLocale, setSelectedLocale] = useState(_locale);
    const { total, headerPage, setHeaderPage } =useCartMonasterio();

    const itemsAuthenticated: MenuItem[] =[
      { label: t`Mis Ordenes`, icon: 'pi pi-fw pi-ticket', command:()=>navigation("/mytickets") },
      { label: t`Cambiar Contraseña`, icon: 'pi pi-fw pi-user-edit', command:()=>setShowChangePassword(true) },
      { separator: true },
      { label: t`Cerrar Sessión`, icon: 'pi pi-fw pi-user', command:()=>{ logOff(); navigation("/"); } }
    ]
    const handlerChangeLocale = (e:any)=>{
        setSelectedLocale(e.value);
        dynamicActivate(e.value.lang)
    }

    const logIn = ()=>{
      setShowLogin(true)
    }
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    useEffect(()=>{
      setHeaderPage(null)
    },[location, setHeaderPage])

    return <>
        <header className="shadow-1 fixed w-full z-2 bg-white">
            <div className="grid m-0">
                <div className="col-2 text-left">
                    <Button className="iconHouse" severity="secondary" icon="pi pi-home" rounded text aria-label="" onClick={()=> navigation("/")} />
                    <a href="#" className="headerlogo" onClick={()=> navigation("/")}><img alt="" className='ml-1 mt-2' src='/logo-2.svg'></img></a>
                </div>
                <div className="col-10 text-right flex justify-content-end flex-wrap">
                    <div className="flex align-items-center justify-content-center">
                        &nbsp; 
                    </div>
                    <div className="flex align-items-center justify-content-center ml-1">
                        <Dropdown value={selectedLocale} 
                            onChange={(e) => handlerChangeLocale(e)} 
                            options={locales} 
                            valueTemplate={selectedLocaleTemplate}
                            itemTemplate={localeOptionTemplate}
                            optionLabel="name" 
                            className=""  />
                    </div>
                    <div className="flex align-items-center justify-content-center ml-2 p-overlay-badge">
                      <Button onClick={()=>navigation("/checkout/products")} className="" severity="secondary" icon="pi pi-shopping-cart" rounded text aria-label=""/>
                      <Badge value={total?.productsQuantity||0}  ></Badge>
                    </div>
                    {isAuthenticated() && !isGuest() &&
                    (<>
                      <Button className="flex align-items-center justify-content-center ml-2" 
                            severity="secondary" icon="pi pi-user" rounded text aria-label=""
                            onClick={(event) => menuAuthenticated.current.toggle(event)}/>
                      <Menu model={itemsAuthenticated} popup ref={menuAuthenticated} id="popup_menu_right" popupAlignment="right" />
                    </>)}
                    {
                      (!isAuthenticated() || isGuest()) &&
                      <>
                        <Button onClick={()=>{logIn()}} className="hidden md:inline-block align-items-center justify-content-center ml-2" severity="secondary" label={t`Iniciar sesión`}></Button>
                        <Button onClick={()=>{logIn()}} className="md:hidden flex align-items-center justify-content-center ml-2" 
                            severity="secondary" icon="pi pi-sign-in" rounded text aria-label=""
                            />
                      </>
                      
                    }
                    
                </div>
            </div>
            { headerPage && 
            <div className="grid m-0 titlePage-rb">
              <div className="col-12 flex">
                <Button onClick={()=>navigation(headerPage.preview)} className="flex align-items-center mr-1" icon="pi pi-arrow-left" rounded text aria-label="" />
                <div className="flex align-items-center"><h1 className="m-0 p-0 text-xl font-semibold">{headerPage.title}</h1></div>
              </div>
              <div className="col-12 hidden"></div>
            </div>}
        </header>
        {showLogin && <LoginControl nonRoute={true} showLogin={showLogin} onClose={()=> setShowLogin(false)}  />}
        { showChangePassword && <ChangePasswordForm showControl={showChangePassword} onClose={()=>setShowChangePassword(false)} /> }
    </>
}

export default Header;