import React, { useState, useRef, useEffect, useMemo  } from 'react'
import { Trans, t } from "@lingui/macro";
import { useSteps } from './../StepItems';
import { Steps } from 'primereact/steps';
import { Button } from 'primereact/button';
import { Checkbox } from "primereact/checkbox";
import { classNames } from 'primereact/utils';

import FormInvoice from './FormInvoice'
import FormDiscount from './FormDiscount'
import FormPaymentMethod from './FormPaymentMethod'
import useCartMonaterio from '../../../contexts/useCartMonaterio';
import { useAuthenticationService } from  '../../../autorization'
import { useForm, Controller } from 'react-hook-form';
import { PAYMETHOD, INVOICETYPE } from './ConstEnum'
import { CheckoutService } from '../../../services/CheckoutService'
import { Toast } from 'primereact/toast';
import buildRequestOrder from './buildRequestOrder'
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { GuestNotificationForm } from './guest-notification-form';
import { BlockSpinner } from '../../../componentes';
import { FormPaymentIzipay } from './form-payment-izipay';
import { useLingui } from '@lingui/react';

export default function CartCheckout() {
  const { i18n } = useLingui()
  const { steps } = useSteps(1);
  const navigate = useNavigate()
  const guestElement:any = useRef()
  const toast:any = useRef(null);
  const form:any = useRef(null);
  const [showPayment, setShowPayment] = useState(false);
  const {control, formState: { errors }, handleSubmit }:any = useForm()
  const { total, products, setHeaderPage } = useCartMonaterio()
  const { isGuest } = useAuthenticationService()
  const [ invoiceData, setInvoiceData ]:any = useState({invoiceType:INVOICETYPE.INVOICEBOLETA})
  const [ payMethod, setPayMethod ] = useState(PAYMETHOD.IZIPAY)
  const [ cuponDiscount, setCuponDiscount ]:any = useState()
  const [ guestEmail, setGuestEmail ] = useState()
  const [ blocked, setBlocked ] = useState(false)
  const [valuesFormIzipay, setValuesFormIzipay]:any = useState();
  
  const formTotal = useMemo(()=>( (total?.totalPrice||0) - (cuponDiscount ? cuponDiscount.descuento: 0)), [cuponDiscount, total]);
  
  useEffect(()=>{
    setHeaderPage({title:t`Carrito de compras`, preview:"/checkout/products"});
  },[])
  const handleInvoice = (data:any)=>{
    setInvoiceData(data)
  }
  const handleDiscount= (data:any)=>{
    setCuponDiscount(data);
  }
  const onChangePayMethod = (data:any)=>{
    setPayMethod(data)
  }
  const notificatePayment= async (order:any)=>{
    
    setValuesFormIzipay(order);
    setShowPayment(true);
  }
  
  const submit = async ()=>{
    if (guestElement && 
        guestElement.current && 
        !guestElement.current.isValid && 
        isGuest())
    {
       
      await guestElement.current.sendSubmit()
      toast.current.show({severity:'error', summary: t`Checkout Error`, detail:t`Ingrese el correo electronico a notificar`, life: 3000});
      return;
    }

    if (!invoiceData || (invoiceData?.invoiceType === INVOICETYPE.INVOICEFACTURA && !invoiceData?.data)){
      toast.current.show({severity:'error', summary: t`Checkout Error`, detail: t`Ingrese los datos de la factura`, life: 3000});
      return;
    }
    const brutoOrder = {
      invoiceData,
      cuponDiscount,
      payMethod,
      products : Object.values(products),
      totalAmount: total.totalPrice,
      email: guestEmail
    }
    const order = buildRequestOrder(brutoOrder);
    setBlocked(true);
    if (payMethod == PAYMETHOD.IZIPAY) await createOrderIzipay(order)
    else if (payMethod == PAYMETHOD.PAGOEFECTIVO) await createOrderPagoEfectivo(order)
  }
  const createOrderPagoEfectivo = async (order:any)=>{
    CheckoutService
    .createOrderPagoEfectivo(order, i18n.locale)
    .then((res:any)=>{
      const {success, orderNumber, messageError} = res.data
      if (success === true){
        navigate(`/checkout/pagoefectivo/${orderNumber}`)
      }else{
        toast.current.show({severity:'error', summary: t`Checkout Error`, detail:messageError, life: 3000});
      }
    })
    .catch(()=>{
      toast.current.show({severity:'error', summary: t`Checkout Error`, detail:t`Ha ocurrido un error, intentelo mas tarde`, life: 3000});
    })
    .finally(()=> setBlocked(false));
  }
  const createOrderIzipay = (order:any)=>{
    CheckoutService
    .createOrderIzipay(order, i18n.locale)
    .then(async (res:any)=>{
      const response = res.data
      if (response.success){
        await notificatePayment(response)
      }
      else{
        toast.current.show({severity:'error', summary: t`Checkout Error`, detail:response.messageError, life: 3000});
      }
    })
    .catch(()=>{
      toast.current.show({severity:'error', summary: t`Checkout Error`, detail:t`Ha ocurrido un error, intentelo mas tarde`, life: 3000});
    })
    .finally(()=> setBlocked(false))
  }
  
  return (
    <div id="CHECKOUT-2-RB">
      {showPayment && 
      <FormPaymentIzipay 
        formToken={valuesFormIzipay?.fromToken} 
        jsPaymentForm={valuesFormIzipay?.jsPaymentForm} 
        publicKey={valuesFormIzipay?.publicKey} 
        successUrl={valuesFormIzipay?.successUrl} 
        refusedUrl={valuesFormIzipay?.refusedUrl}
      />}
      <div className="container-rb pt-7 pb-4 surface-100">
          <Steps model={steps} activeIndex={1} className="pt-7" />
      </div>
      <div className="container-rb pt-5">
          <div className="grid m-0">
            <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
              <div className="grid mx-0 md:px-4">
                {
                  isGuest() &&
                  <GuestNotificationForm ref={guestElement} onChangeGuestEmail={(e:any)=>setGuestEmail(e.email)}  />
                }
                
                <div className="col-12 pb-3 md:pb-4">
                    <h3 className="my-0"><Trans>Pago</Trans></h3>
                </div>
                <div className="formgrid grid mx-0">
                  <FormInvoice onChangeInvoice={(d:any)=>  handleInvoice(d)} />
                  {/* <FormDiscount onChangeDiscount = {(d:any)=>  handleDiscount(d)} /> */}
                  <FormPaymentMethod method={payMethod} onChangePayMethod = {(d:any)=>  onChangePayMethod(d)} />
                </div>
              </div>
            </div>
              <form onSubmit={handleSubmit(submit)} ref={form} className="col-12 md:col-4">
                <div className="grid mx-0 md:p-3 md:ml-4 md:shadow-2 border-round-xl">
                      <div className="col-12"><h3 className="my-2 md:mt-0"><Trans>Resumen de tu compra</Trans></h3></div>
                      {
                        cuponDiscount &&
                        <>
                          <div className="col-7 text-sm font-medium underline"><Trans>Cupon de Descuento</Trans></div>
                          <div className="col-5 text-sm font-bold text-right underline">{ i18n.number(cuponDiscount?.descuento, { style: "currency", currency:"PEN" })}</div>
                        </>
                      }
                      
                      <div className="col-7 text-lg font-medium"><Trans>Total a pagar</Trans></div>
                      <div className="col-5 text-lg font-bold text-right">{ i18n.number(formTotal, { style: "currency", currency:"PEN" })}</div>
                      <div className="col-12">
                          <div className="formgrid grid mx-0">
                              <div className="field col-12 px-0 flex align-items-start flex-wrap">
                                  <div className="flex align-items-center justify-content-center mr-2">
                                    <Controller name="accept" control={control} rules={{ required: true }} render={({ field, fieldState }) => (
                                      <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value} className={classNames(({ 'p-invalid': fieldState.invalid }))}  />
                                    )} />
                                    
                                  </div>
                                  <span className={classNames({ 'p-error': errors.accept, "flex-1 align-items-center justify-content-center": true })} >
                                    * <Trans id="termsconditions">He leído y acepto los &nbsp;
                                        <Link to="terms">términos y condiciones</Link>&nbsp; 
                                        de santacatalina.org.pe y autorizo la &nbsp;
                                        <Link to="returns">política de Devolución.</Link>
                                      </Trans>
                                  </span>
                              </div>
                          </div>
                      </div>
                      <div className="col-12">
                          <Button  className="font-semibold w-full" size="large" icon="" label={t`Continuar con la compra`} />
                      </div>
                </div>
              </form>
          </div>
      </div>
      <Toast ref={toast} />
      <BlockSpinner uiBlocked={blocked} />
      <Outlet />
    </div>
  )
}
