import { useCartMonasterioContext } from './CartMonasterioProvider';
import useCartMonasterioProducts from './useCartMonasterioProducts'
import useCartMonasterioTotal from './useCartMonasterioTotal'
const useCartMonasterio = ()=>{
  const { addProduct, products, removeProduct, addVideo, isAppendVideo, clearProducts } = useCartMonasterioProducts();
  const { total, updateCartTotal } = useCartMonasterioTotal()
  const { setHeaderPage, headerPage } =    useCartMonasterioContext()
  
  return {
    addProduct, 
    addVideo,
    isAppendVideo,
    products, 
    removeProduct, 
    total,
    updateCartTotal,
    clearProducts,
    setHeaderPage, 
    headerPage
  }
}


export default useCartMonasterio;