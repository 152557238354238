import { useEffect } from "react"
import { Dialog } from "primereact/dialog";
import { t } from "@lingui/macro";
const FormPaymentIzipay = ({ formToken, jsPaymentForm, publicKey, successUrl, refusedUrl }:any)=>{
  useEffect(()=>{
    const head = document.querySelector("head");
    const scriptForm = document.createElement("script");
    scriptForm.setAttribute("type", "text/javascript")
    scriptForm.setAttribute("src", 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/stable/kr-payment-form.min.js')
    scriptForm.setAttribute("kr-public-key", publicKey)
    scriptForm.setAttribute("kr-post-url-success", successUrl)
    scriptForm.setAttribute("kr-post-url-refused", refusedUrl)

    const scriptNeon = document.createElement("script");
    scriptNeon.setAttribute("type", "text/javascript")
    scriptNeon.setAttribute("src", 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/neon.js')

    const linkNeon = document.createElement("link");
    linkNeon.setAttribute("rel", "stylesheet")
    linkNeon.setAttribute("href", 'https://static.micuentaweb.pe/static/js/krypton-client/V4.0/ext/neon-reset.min.css')

    head?.appendChild(scriptForm);
    head?.appendChild(linkNeon);
    head?.appendChild(scriptNeon);
    return ()=>{
      head?.removeChild(scriptForm);
      head?.removeChild(linkNeon);
      head?.removeChild(scriptNeon);
    }
  },[])

  return(
    <Dialog header={t`No cierre esta ventana.`} onHide={()=>{}} visible={true}>
      <div className="kr-embedded" kr-form-token={formToken}>
        
      </div>
    </Dialog>
    
  );
}
export { FormPaymentIzipay } 