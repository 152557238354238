import { Steps } from "primereact/steps"
import { useSteps } from "../StepItems";
import { Trans, t } from "@lingui/macro";
import { useEffect, useState } from "react";
import { Skeleton } from "primereact/skeleton";
import useCartMonaterio from "../../../contexts/useCartMonaterio";
import { TicketService } from "../../../services";
import { useNavigate, useParams } from "react-router-dom";

const CartOrderPagoEfectivoCip = ()=>{
  const { ordernumber } = useParams()
  const navigate = useNavigate()
  const { steps } = useSteps(2);
  const [order, setOrder]:any = useState(null)
  const [blocked, setBlocked] = useState(true);
  const { setHeaderPage, clearProducts } = useCartMonaterio()
  useEffect(()=>{
    setBlocked(true)
    setHeaderPage({title:t`Carrito de compras`, preview:"/"});
    TicketService
    .GetOrderPendings(ordernumber)
    .then((r)=>{ setOrder(r.data); return r;})
    .then((r)=>{ if (!r.data || !r.data.paymentPagoEfectivo) navigate("/"); })
    .finally(()=>setBlocked(false))

  },[])
  return (<>
  <div id="CHECKOUT-3-RB" >
    <div className="container-rb pt-7 pb-4 surface-100">
      <Steps model={steps} activeIndex={2} className="pt-7" />
    </div>
    <div className="container-rb pt-5">
      <div className="grid m-0">
          <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
              <div className="grid mx-0 md:px-4">
                  <div className="col-12 pb-3 md:pb-2">
                      <h2 className="mt-0"><Trans>Orden Pendiente de pago</Trans> #{order?.codigo}</h2>
                      <div className="text-lg"><Trans>Siga las instrucciones de PagoEfectivo para pagar su orden, recuerde que tiene media hora para pagar. Inmediatamente despues de recibir su pago se enviará un correo electrónico a</Trans> <span className="font-medium">{order?.email}</span> <Trans> con el detalle de su orden. ¡Gracias y que disfrutes de tu visita!</Trans></div>
                  </div>
                  { blocked && <Skeleton width="100%" height="25rem"></Skeleton> }
                  { !blocked && <iframe src={order?.paymentPagoEfectivo?.urlCip}></iframe> }
              </div>
          </div>
      </div>
    </div>
  </div>
  </>)
}

export { CartOrderPagoEfectivoCip }