import { useLingui } from "@lingui/react";
import { useEffect, useState } from "react"


const useVisitTranslate = (_metadata:string)=>
{ 
  const { i18n } = useLingui()
  const [metadata] = useState(_metadata)
  const [images, setImages] = useState([])
  const [description, setDescription] = useState(null)
  const [data, setData] = useState(null)
  const [about, setAbout] = useState(null)
  
  useEffect(()=>{
    
    const dataParsed = parse(metadata);
    set(dataParsed);
  },[i18n.locale, _metadata])

  const set = (dataParsed:any)=> {
    setData(dataParsed)
    setImages([])
    setDescription(null)
    setAbout(null)
    if (dataParsed){
      setImages(_mapImage(dataParsed, i18n.locale));
      setDescription(_information(dataParsed, i18n.locale))
      setAbout(_about(dataParsed, i18n.locale))
    }
  }
  const parse = (metadata:any)=>{
    try {
      return JSON.parse(metadata);
    } catch (error) {
      console.log(error, metadata);
      return null
    }
  }

  const _mapImage = (metadata:any, locale:any)=>{
    const { images }:any = metadata;
    return images.map((i:any)=>{
      return {
         itemImageSrc: i.itemImageSrc
        ,thumbnailImageSrc: i.thumbnailImageSrc
        ,alt: ""
        ,title: ""
      }
    })
  }

  const _about = (metadata:any, locale:any)=>{
    const { about }:any = metadata[locale];
    if (about instanceof Array)
      return about.join("");
    else return about
  }

  const _information = (metadata:any, locale:any)=>{
    const { information }:any = metadata[locale];
    if (information instanceof Array)
      return information.join("");
    return information;
  }
  
  return { images
    ,description
    ,data
    ,about}

}
export { useVisitTranslate }