function esnumero(campo:any){ return (!(isNaN( campo )));}
function trim(cadena:any){
  let cadena2 = "";
  let len = cadena.length;
  for ( var i=0; i <= len ; i++ ) if ( cadena.charAt(i) != " " ){cadena2+=cadena.charAt(i);   }
  return cadena2;
}
function valruc(valor:any) {
  valor = valor.trim();
  if (esnumero(valor)) {
    if (valor.length == 8) {
      let suma = 0;
      for (let i = 0; i < valor.length - 1; i++) {
        const digito = valor.charAt(i) - 0;
        if (i == 0) suma += digito * 2;
        else suma += digito * (valor.length - i);
      }
      let resto = suma % 11;
      if (resto == 1) resto = 11;
      if (resto + (valor.charAt(valor.length - 1) - 0) == 11) {
        return true;
      }
    } else if (valor.length == 11) {
      let suma = 0;
      let x = 6;
      for (let i = 0; i < valor.length - 1; i++) {
        if (i == 4) x = 8;
        const digito = valor.charAt(i) - 0
        x--;
        suma += digito * x;
      }
      let resto:any = suma % 11;
      resto = 11 - resto;

      if (resto >= 10) resto = resto - 10;
      if (resto == valor.charAt(valor.length - 1) - 0) {
        return true;
      }
    }
  }
  return false;
}
export function esrucok(ruc:any){
  return (!( !ruc || !esnumero(ruc) || ruc.length!==11 || !valruc(ruc) ));
}