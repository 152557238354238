import { LoginConsts } from "../autorization"

export function getHeaders(){
  const token = localStorage[LoginConsts.ACCESS_TOKEN_KEY]
  if (token){
    const accessToken  = JSON.parse(token) 
    if (accessToken){
      return {
        Authorization:`Bearer ${accessToken}`
      }
    }
  }
  
}