import axios from 'axios';
import { LoginConsts } from '../autorization';
import urlBase from './Configuration'
import { getHeaders } from './TokenHeader';

class CheckoutService {
  
  public static createOrderIzipay(order:any, locale:any): Promise<any>{
      return axios.post(`${urlBase}/checkout/izipay?locale=${locale}`, order, {headers: getHeaders()});
  }
  public static createOrderPagoEfectivo(order:any, locale:any): Promise<any>{
    return axios.post(`${urlBase}/checkout/pagoefectivo?locale=${locale}`, order, {headers: getHeaders()});
  }
  
  public static validationPayment(order:any): Promise<any>{
    return axios.post(`${urlBase}/checkout/validations/${order}/payment`, order, {headers: getHeaders()});
  }

}
export { CheckoutService }