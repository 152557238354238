import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import { Trans} from "@lingui/macro";


const TermsPolitics = ({onHide}:any)=>{
  const navigate =useNavigate()
  const hide = ()=>{
    if (onHide) onHide()
    else navigate('..')
  }
  return (
    <Sidebar blockScroll={true} dismissable={true} visible={true} position="right" onHide={() => hide()} className="w-full md:w-3">
      <div id="TERMS" className="">
        <h3><Trans>Términos y condiciones</Trans></h3>
        <ul className="pl-0 text-justify">
            <li className="list-none"><h4><Trans>Compra online</Trans></h4></li>
            <li className="ml-3"><p className="text-justify"><Trans>Las compras realizadas por este medio no permiten anulación o devolución. Por favor verifica tu compra antes de pagar. ¡Gracias!</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>El uso de las entradas adquiridas por este medio, solo es válido para el día que indica la orden de compra.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>No está permitido el uso de flash para fotografías de lienzos y pintura mural y en los ambientes donde se indique, asimismo, no está permitido el uso de drones.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Está prohibido el ingreso y consumo de bebidas alcohólicas y/o de personas en estado etílico. Está prohibido fumar dentro del monasterio.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Antes de ingresar debes mostrar al Portero tu entrada o código QR impreso o desde tu celular para que realice la verificación en el sistema.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>La compra por este medio emite automáticamente boleta de venta. Puedes solicitar factura al momento de realizar tu compra, te llegará en 24 horas.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>El ingreso de mascotas está restringido a aquellas que sean soporte emocional de su dueño, condición debidamente acreditada.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Los adultos mayores, personas con discapacidad, madres gestantes o con niños pequeños tienen atención preferencial de acuerdo a la Ley Nº 27408.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Toma en cuenta el horario escogido para tu visita y respeta la misma para evitar aglomeraciones.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Niños a partir de 7 años pagan entrada.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Agradeceremos cuides tus pertenencias. El Monasterio de Santa Catalina no se responsabiliza por pérdidas o robos.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>El servicio de guiado es opcional, no está incluido en el precio del boleto, en caso de requerir este servicio lo podrás contratar al momento de tu ingreso y pagarlo directamente a la guía (personal independiente)</Trans>.</p></li>
            <li className="ml-3"><p className="text-justify"><Trans>No se permitirá el ingreso de personas que no presenten la entrada virtual con su respectivo código.</Trans></p></li>
            <li className="ml-3"><p className="text-justify"><Trans>Se debe respetar el ingreso al Monasterio de Santa catalina de acuerdo al orden de llegada.</Trans></p></li>
        </ul>
        <ul className="pl-0 text-justify">
            <li className="list-none"><h4><Trans>Pagos online</Trans></h4></li>
            <li className="ml-3"><p className="text-justify"><Trans>Para el pago con Tarjeta de Crédito y Débito Visa, MasterCard o American Express utilizamos la plataforma de pagos Izy Pay. Esta plataforma realizará  el cobro en nuevos soles. Si la cuenta asociada a tu tarjeta es en dólares, el tipo de cambio utilizado será el de tu banco.</Trans></p></li>
        </ul>
      </div>
    </Sidebar>)
}

export { TermsPolitics };