
import { zodResolver } from "@hookform/resolvers/zod";
import { Trans, t } from "@lingui/macro";
import { InputText } from 'primereact/inputtext';
import { forwardRef, useImperativeHandle } from "react";
import { useForm, Controller } from 'react-hook-form';
import { z } from 'zod';

const GuestNotificationForm  = forwardRef(({onChangeGuestEmail}:any, ref)=>{
  
  const schema = z.object({
    email: z.string({required_error:t`Ingrese un email`}).email({message:t`Ingrese un email`})
  })
  const defaultValues = {email:"" }
  const {control, formState: { errors, isValid },  handleSubmit, getValues }:any = useForm({defaultValues, resolver: zodResolver(schema)})
  const getFormErrorMessage = (name:any) => {
    return (errors[name]) && <small className="p-error">{errors[name].message}</small>
  };
  

  const onChangeEmail = (e:any, field:any)=>{
    field.onChange(e);
    setTimeout(()=>{
      handleSubmit(submit,invalid)();
    })
  }
  const invalid = (d:any)=>{
    onChangeGuestEmail && onChangeGuestEmail({email:""})
  }
  const submit = (d:any)=>{
    onChangeGuestEmail && onChangeGuestEmail(d)
  }
  useImperativeHandle(ref, ()=>({
    sendSubmit: handleSubmit(submit,invalid),
    getValues,
    isValid
  }))
  return (
    <>
    <form >
      <div className="col-12 pb-3 md:pb-4">
        <h3 className="my-0"><Trans>Notificar a:</Trans></h3>
      </div>
      <div className="formgrid grid mx-0">
        <div className="col-12 pb-3">
          <span className="font-medium"><Trans>Ingresar un correo electronico</Trans></span>
        </div>
        <div className="field col-12 pb-3">
          <Controller name="email" control={control} rules={{ required: true }} render={({ field }) => (
            <InputText 
              id={field.name} 
              onBlur={field.onBlur} 
              ref={field.ref} 
              onChange={(e)=>onChangeEmail(e, field)} 
              value={field.value}  placeholder={t`Correo Electronico`} className="w-full" />
          )} />
          {getFormErrorMessage("email")}
        </div>
      </div>
    </form>
      
    </>
  )
})

export { GuestNotificationForm }