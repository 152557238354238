import axios from 'axios';
import urlBase from './Configuration'
//const urlBase = 'https://localhost:44324';
const UserService ={
  getRegisterNewUser: (registerUser:any):Promise<any>=>{
    return axios.post(`${urlBase}/userAccess/userRegistrations`, registerUser);
  },

  sendRecoveryPassword: (email:any):Promise<any>=>{
    return axios.post(`${urlBase}/user/recovery/ship?email=${encodeURIComponent(email)}`);
  },

  recoveryPassowrReset: (req:any):Promise<any>=>{
    return axios.post(`${urlBase}/user/recovery/reset`, req);
  }
}
export { UserService }