import { useCartMonasterioContext, ICartTotal } from "./CartMonasterioProvider"

const useCartMonasterioTotal = () => {
  const { total, setTotal } = useCartMonasterioContext();
  const updateCartTotal = (products:any)=>{
    const prodcts = Object.values(products);
    const t:ICartTotal ={
      productsQuantity : prodcts.length,
      visitsAllQuantity : prodcts.reduce((p:any, e:any)=> p + (e.prices || []).reduce((p:any, e:any)=> p + e.totalCount, 0), 0)  as number,
      totalPrice : prodcts.reduce((p:any, e:any)=> p + (e.amountTotal), 0)  as number
    }
    setTotal(t);
  }
  return {
    total,
    updateCartTotal,
  };
}
export default useCartMonasterioTotal;