import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { detectedLocale, dynamicActivate } from "./i18n"
import { CartMonasterioProvider } from "./contexts/CartMonasterioProvider"
import { AutorizationProvider } from "./autorization"
const I18nApp = () => {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    dynamicActivate(detectedLocale)
  }, [])

  return (
    <>
    
    <I18nProvider i18n={i18n}>
      <AutorizationProvider>
        <CartMonasterioProvider>
          <App />
        </CartMonasterioProvider>
      </AutorizationProvider>
    </I18nProvider>
    </>
    
  )
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <I18nApp />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
