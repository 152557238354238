import React, { useEffect, useState } from 'react'
import { Trans, t } from "@lingui/macro";
import { Button } from 'primereact/button';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dropdown } from 'primereact/dropdown';
import { INVOICETYPE, MODE } from './ConstEnum'
import { LocationService }  from '../../../services/LocationService'
import * as z from 'zod';
import { esrucok } from './ruc-Validations'
import { GetCompany } from '../../../services/ConsultarRucService';
export default function FormInvoice({onChangeInvoice, onHandleSubmit, typeInvoice = INVOICETYPE.INVOICEBOLETA}:any) {
  
  const [mode, setMode]:any = useState(MODE.MODEEDIT)
  const [invoiceType, setInvoiceType]:any = useState(typeInvoice)
  const [dataForm, setDataForm]:any = useState()
  const [readOnlyInfo, setReadOnlyInfo] = useState(true);
  const defaultForm = {
    ruc:'',
    businessName:'',
    address:'',
    email:'',
  }
  const schema = z.object({
    ruc: z.string().refine(esrucok, {message:t`Ingrese un ruc válido`}),//({required_error:}).nonempty({message:t`Ingrese un ruc válido`}),
    businessName: z.string({required_error:t`Ingrese una razón social`}).nonempty({message:t`Ingrese una razón social`}),
    address: z.string({required_error:t`Ingrese una dirección`}).nonempty({message:t`Ingrese una dirección`}),
    email:z.string({required_error:t`Ingrese un correo electrónico`}).email({message:t`Correo electónico invalido`}),
  })
  const {control, formState: { errors }, handleSubmit, setValue, reset, getValues, setError }:any = useForm({resolver: zodResolver(schema), defaultValues:defaultForm})
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const clearForm = ()=>{
    reset();
    setMode(MODE.MODEEDIT);
  }
  const onSubmit = (data:any)=>{
    
  
    setDataForm(data);
    onChangeInvoice && onChangeInvoice({invoiceType, data})
    setMode(MODE.MODEREAD);
  }
  const changeInvoice=(type:any)=>{
    setInvoiceType(type)
    onChangeInvoice && onChangeInvoice({invoiceType:type})
    
  }
  const clearInfo=() => {
    setValue('businessName', '')
    setValue('address', '')
    setValue('email', '')  
  }
  const getInfoCompany= async (ruc: string) => {
    if (ruc.length === 11) {
      try {
        clearInfo()
        setReadOnlyInfo(true);
        const response = await GetCompany(ruc);
        if (response.status === 200) {
          setValue('businessName', response.data.RazonSocial)
          setValue('address', response.data.Direccion)
          // Esto es si no encuentra el RUC en el servicio (Ejm. 20601818672).
          if (response.data.Mensaje !== "OK"){
            setReadOnlyInfo(false);
          }
        } else { // Aqui deberia poner  response.status === 204
          setReadOnlyInfo(false);
        }
        setValue('ruc', ruc)
      } catch (error) { // Esto es si el servicio falla.
        setValue('ruc', ruc)
        setReadOnlyInfo(false);
      }
    } else {
      clearInfo()
    }
  }
  return (
   <>
    <div className="col-12 pb-3">
        <span className="font-medium"><Trans>Seleccione el comprobante que deseas</Trans></span>
    </div>
    <div className="field col-12 pb-3 flex md:col-7">
        <div onClick={()=> changeInvoice(INVOICETYPE.INVOICEBOLETA)} className={`${invoiceType === INVOICETYPE.INVOICEBOLETA?"opt-selected-rb":""} opt-rb font-medium flex-1 py-1 border-round-sm text-center line-height-4 border-1 border-400 cursor-pointer`}>
          <Trans>Boleta</Trans>
        </div>
        <div onClick={()=> changeInvoice(INVOICETYPE.INVOICEFACTURA)} className={`${invoiceType === INVOICETYPE.INVOICEFACTURA?"opt-selected-rb":""} opt-rb font-medium flex-1 py-1 border-round-sm text-center line-height-4 border-1 border-400 cursor-pointer ml-2 md:ml-3`}>
          <Trans>Factura</Trans>
        </div>
    </div>
    {
      invoiceType === INVOICETYPE.INVOICEFACTURA &&
      <div className="field col-12 pb-3">
        {
          mode === MODE.MODEREAD &&
          <div className="surface-100 border-round-xl w-full py-3 p-2 md:p-3">
            <div className="col-12 pt-1 pb-3">
                <span className="font-medium opacity-60"><Trans>Datos de facturación</Trans></span>
            </div>
            <div className="col-12">
                <h3 className="mt-0 mb-2">{ dataForm?.businessName }</h3>
                <div className="py-1">RUC { dataForm?.ruc }</div>
                <div className="py-1">{dataForm?.address }</div>
                <div className="pt-1">{dataForm?.email}</div>
                <div className="pt-3 flex justify-content-between flex-wrap">
                    <Button onClick={()=> setMode(MODE.MODEEDIT) } className="flex align-items-center justify-content-center font-bold" label={t`editar`} link />
                    <Button onClick={()=> clearForm() } className="flex align-items-center justify-content-center font-bold" severity="secondary" label={t`eliminar`} link />
                </div>
            </div>
          </div>
        }
        {
          mode === MODE.MODEEDIT &&
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formgrid grid mx-0 p-2 md:p-3 shadow-2 border-round-xl md:p-3">
                <div className="col-12 pb-4">
                    <h3 className="mt-2 mb-0 md:mt-0"><Trans>Ingresa los datos de facturación</Trans></h3>
                </div>
                <div className="field col-12 md:col-4">
                  <Controller name="ruc" control={control} render={({ field, fieldState }) => (
                    <InputText
                      id={field.name} {...field}
                      autoFocus
                      value={field.value}
                      onChange={
                        (e) => {
                          field.onChange(e.target.value)
                          getInfoCompany(e.target.value)
                        }
                      }
                      minLength={11}
                      maxLength={11}
                      placeholder="RUC"
                      className="w-full"
                    />
                    )} />
                    {getFormErrorMessage("ruc")}
                </div>
                <div className="field col-12 md:col-8">
                  <Controller name="businessName" control={control} render={({ field, fieldState }) => (
                      <InputText id={field.name} {...field} placeholder="Razón social" className="w-full" readOnly={readOnlyInfo} />
                      )} />
                  {getFormErrorMessage("businessName")}
                </div>
                <div className="field col-12 md:col-12">
                  <Controller name="address" control={control} render={({ field }) => (
                      <InputText id={field.name} {...field} placeholder="Dirección" className="w-full" readOnly={readOnlyInfo} />
                  )} />
                  {getFormErrorMessage("address")}
                </div>
                <div className="field col-12 md:col-12">
                  <Controller name="email" control={control} render={({ field }) => (
                      <InputText id={field.name} {...field} placeholder="Correo electronico" className="w-full" />
                  )} />
                  {getFormErrorMessage("email")}
                </div>
                <div className="field col-12 flex md:col-6 md:col-offset-6">
                    <Button onClick={()=>setMode(MODE.MODEREAD)} type="button" className="font-semibold flex-1 mr-2" severity="secondary" label={t`Cancelar`} outlined />
                    <Button type="submit" className="font-semibold flex-1" severity="secondary" label={t`Guardar`} />
                </div>
            </div>
          </form>
        }
      </div>
    }
    
   </>
  )
}
