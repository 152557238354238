import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import ProductTypeVisit   from '../visits/ProductTypeVisit';
import { useLingui } from '@lingui/react';
import useCartMonasterio from './../../contexts/useCartMonaterio'
import { Outlet } from 'react-router-dom';

export default function Home() {
  useLingui() // Por lo tanto, el uso t de ayudantes de macro o núcleo no se vuelve a procesar a menos que su componente use useLingui
  const { addVideo,
    isAppendVideo, } = useCartMonasterio()
  return (
    <>
      <div id="HOME-RB" >
        <div className="container-rb pt-4">
            <div className="grid m-0 pt-7 pb-3">
                <div className="col-12 text-center">
                    <img alt="" className="logo w-10 md:hidden" src="/logo-monasterio.svg" />
                    <img alt="" className="logo-2 hidden w-8 md:inline-block" src="/logo-2.svg" />
                    <h1 className="text-xl md:text-4xl"><Trans>¡Una ciudad dentro de la ciudad!</Trans></h1>
                    <p className="md:text-lg"><Trans>Visita el mayor atractivo turístico de Arequipa, más de 400 años de historia y cultura.</Trans></p>
                </div>
            </div>
            <div className="grid m-0 pb-6 flex align-items-stretch flex-wrap">
                <div className="col-12 md:col-4 flex align-items-center justify-content-center">
                    <ProductTypeVisit
                      caption={t`Visita presencial`}
                      srcImage="/home/vpresencial.jpg"
                      id="E18E1679-F7AD-4507-959F-088102E4B15A"
                    />
                    
                </div>
                <div className="col-12 md:col-4">
                  <ProductTypeVisit
                        caption={t`Visita nocturna`}
                        srcImage="/home/vnocturna.jpg"
                        id="5B1496E7-0932-457B-A176-FFD30E890A8A"
                      />
                  <ProductTypeVisit
                        className="itemVisit shadow-2 cursor-pointer mt-3"
                        caption={t`Días con promoción para peruanos`}
                        srcImage="/home/vperuanidad.jpg"
                        id="66F13F15-7A4A-43AE-9954-F6EFCBBFB9D0"
                      />
                </div>
                <div className="col-12 md:col-4 flex align-items-center justify-content-center">
                    <ProductTypeVisit
                        caption={t`Visita guiada teatralizada`}
                        srcImage="/home/vteatralizada.jpg"
                        id="0D68C83E-BDE1-4571-8020-26537A4E52D2"
                      />
                </div>
            </div>
        </div>

        <div className="container-rb bg-red">
            <div className="grid m-0 py-6">
                <div className="col-12 text-center md:col-4 md:col-offset-1">
                    <img alt="" className="w-10 md:w-full md:pr-4" src="/home/vvirtual.png" />
                </div>
                <div className="col-10 col-offset-1 text-center md:col-6 md:text-left md:col-offset-0 pr-6">
                    <h3 className="text-white mt-1 md:mt-1 md:mb-2 md:text-3xl"><Trans id="VDEOPU">Video: Puerta abierta entre 2 mundos</Trans></h3>
                    <p className="text-white font-bold mb-1"><Trans id="CUPV">Compra un paseo virtual (peruanos y extranjeros 10.00 PEN)</Trans></p>
                    <p className="text-white mt-1"><Trans id="C7CSF">Contiene 7 capítulos sobre la fascinante historia del Monasterio de Santa Catalina – Único en el mundo con ciudadela – ubicado en Arequipa (Perú), una ciudad considerada por la UNESCO como Patrimonio Cultural de la Humanidad. Una vez comprado el video, dispone de un acceso de 7 dias para su reproducción.</Trans></p>
                    {
                      isAppendVideo || 
                      <Button onClick={ (e)=>{ addVideo() } } className="font-semibold" icon="pi pi-cart-plus" label={t`Agregar al carrito`} text raised />
                    }
                    
                </div>
            </div>
        </div>
        <div className="container-rb">
            <div className="grid m-0">
                <div className="col-12 md:col-10 md:col-offset-1">
                    <h3 className="text-center md:text-3xl"><Trans>Preguntas frecuentes</Trans></h3>
                    <Accordion activeIndex={0}>
                        <AccordionTab header={t({id:"NPUE", message:`No pude utilizar mi entrada por motivos de fuerza mayor, ¿la puedo utilizar en otra fecha?`})}>
                            <p className="m-0">
                              <Trans>Sí podrá hacerlo dentro de los 11 meses siguientes a su compra. Las entradas compradas para día con promoción para peruanos podrán ser utilizadas solamente en los días con promoción (día 10 y último domingo de cada mes).</Trans>
                            </p>
                        </AccordionTab>
                        <AccordionTab header={t({id:"NMHL", message:`No me han llegado mis entradas, ¿cuándo me las van a enviar?`})}>
                            <p className="m-0">
                              <Trans>No enviamos entradas, podrá ingresar con el código QR que ha llegado a su correo.</Trans>
                            </p>
                        </AccordionTab>
                        <AccordionTab header={t({id:"STCE", message:`¿Si tengo carnet de extranjería puedo acceder al precio con promoción para peruanos?`})}>
                            <p className="m-0">
                              <Trans>Sí, tiene que presentarlo en Portería al momento de ingresar.</Trans>
                            </p>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
        </div>
      </div>
      <Outlet />
    </>
  )
}
