import axios from 'axios';
import urlBase from './Configuration'
//const urlBase = 'https://localhost:44324';
const LoginService ={
  
  autenticate: (username:string, password: string):Promise<any>=>{
    var formdata = new FormData();
        formdata.append("client_id", "ro.client");
        formdata.append("grant_type", "password");
        formdata.append("username", username);
        formdata.append("client_secret", "secret");
        formdata.append("password", password);
    const data = { 
      'client_id': "ro.client",
      "grant_type": "password",
      "username": username,
      "client_secret": "secret",
      password:password
    };
    return axios({
      method: "POST",
      headers:{
        "Content-Type":'application/x-www-form-urlencoded'
      },
      url: `${urlBase}/connect/token`,
      data: data,
      
    });
    
  }
}
export { LoginService }