import { Trans, t } from "@lingui/macro"
import { InputText } from "primereact/inputtext";
import { BlockSpinner, VisitDetail } from "../../componentes";
import { Controller, useForm } from "react-hook-form";
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useRef, useState } from "react";
import { TicketService } from "../../services";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { useLingui } from '@lingui/react';
import { useInterval } from "primereact/hooks";
const CheckTicket = ()=>{
  useLingui()
  const schema = z.object({
    qrcode: z.string({required_error:t`Escriba un codigo de ticket`}).regex(/^(^(MONS-)(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])([12]\d{3})-([0-9]*[1-9][0-9]*)+-([0-9]*[1-9][0-9]*)+)$/, t`EL QR no tiene el formato correcto.`),
  });
  
  const { handleSubmit, control, formState: { errors }, reset }:any = useForm({defaultValues:{qrcode:""},resolver:zodResolver(schema)})
  const [blocked, setBlocked] = useState(false);
  const [, setOrder]:any = useState();
  const [visit, setVisit]:any = useState();
  const [showMessage, setShowMessage]:any = useState();
  const reForm:any = useRef(null)
  const [activeFocus, setActiveFocus] = useState(true);
  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };

  useInterval(()=>setFocus(), 500, activeFocus);

  const closeVisitDetail = ()=>{
    setVisit(null);
    setFocus()
    setActiveFocus(true);
  }

  const setFocus = ()=>{
    if (reForm?.current){
      const { elements:{ qrcode } }:any = reForm.current
      qrcode?.focus()
    }
  }
  
  const _handlePage =(e:any)=>{
    e.preventDefault();
    handleSubmit(onSumit)();
  }
  const onSumit = (e:any)=>{
    const lastGion = e.qrcode.lastIndexOf("-")
    const orderNumber = e.qrcode.substring(0, lastGion);
    const itemNumber = parseInt(e.qrcode.substring(lastGion + 1)) 
    setBlocked(true)
    TicketService
    .GetOrderConfirmed(orderNumber)
    .then((res)=> { 
      const o = res.data;
      if (o){
        setOrder(o); 
        const value = o.visitas.find((x:any)=>x.item === itemNumber)
        if (value) 
        {
          setVisit(value)
          setActiveFocus(false);
          return res;
        }
      }
      setShowMessage({message:`No existe el ticket consultado`, title: `No existe el Ticket`})
      return res; 
    })
    .then(()=> reset())
    .finally(()=>setBlocked(false));
  }
  
  return (<>
    <div id="CHECK-RB" >
      <div className="container-rb pt-4">
        <div className="grid m-0 pt-7 pb-3">
          <div className="col-12 text-center">
              <img alt="" className="logo w-10 md:hidden" src="/logo-monasterio.svg" />
              <img alt="" className="logo-2 hidden w-8 md:inline-block" src="logo-2.svg" />
              <h1 className="text-xl md:text-4xl"><Trans>¡Una ciudad dentro de la ciudad!</Trans></h1>
              <p className="md:text-lg"><Trans>Visita el mayor atractivo turístico de Arequipa, más de 400 años de historia y cultura.</Trans></p>
          </div>
        </div>
        <div className="grid m-0 pb-6 flex align-items-stretch flex-wrap">
          <div className="field col-12 text-center">
            <form ref={reForm} onSubmit={_handlePage}>
              
                <Controller name="qrcode" control={control} render={({ field }) => (
                  <span className="p-input-icon-left">
                    <i className="pi pi-qrcode"  />
                    <InputText autoFocus id={field.name} {...field} maxLength={22} placeholder={t`Ingrese el codigo QR`}  className="p-inputtext-lg" autoComplete="off" />
                  </span>
                    )} />
                  <br />
                  {getFormErrorMessage("qrcode")}
            </form>
          </div>
        </div>
      </div>
    </div>
    
    <BlockSpinner uiBlocked={blocked} />
    {
      showMessage &&
      <Dialog visible={true} 
        onHide={() =>{  setShowMessage(null) }} 
        closeOnEscape={true}
        position="top" 
        footer={<div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => setShowMessage(null) } /></div>} 
        showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
       <div className="flex align-items-center flex-column pt-6 px-3">
           <i className="pi pi-exclamation-circle" style={{ fontSize: '5rem', color: 'var(--red-500)' }}></i>
           <h5 className="alert-check-in" ><Trans>{showMessage.title}</Trans></h5>
           <p className="alert-check-in-message">
            {showMessage.message}
           </p>
       </div>
      </Dialog>
    }
    {visit && <VisitDetail validate={true} visitor={visit} onClose={()=> closeVisitDetail() } />}
  </>)
}
export { CheckTicket }