const MODEREAD ="read"; 
const MODEEDIT ="edit"; 
const INVOICEBOLETA =25; 
const INVOICEFACTURA =26
const IZIPAY = 19
const PAGOEFECTIVO = 20
export const MODE = {
  MODEREAD,
  MODEEDIT
}

export const INVOICETYPE = {
  INVOICEBOLETA,
  INVOICEFACTURA
}

export const PAYMETHOD = {
  IZIPAY,
  PAGOEFECTIVO
}