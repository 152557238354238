
import React, { useEffect, useState } from 'react';

import { ProgressSpinner } from 'primereact/progressspinner';

const BlockSpinner = ({ uiBlocked }:{uiBlocked:boolean})=>{
  const [blocked, setBlocked] = useState(false);
  useEffect(()=>{
    setBlocked(uiBlocked)
    uiBlocked ?
    document.body.style.overflow = "hidden"
    :
      document.body.style.overflow = "";
    
  },[uiBlocked])
  return (
    <>
      {blocked && <div className='loading-indicator'><ProgressSpinner></ProgressSpinner></div>}
    </>
    
  )
}

export { BlockSpinner } 