import { i18n, Messages } from "@lingui/core"
import { detect, fromUrl, fromStorage, fromNavigator } from "@lingui/detect-locale";
import { addLocale } from 'primereact/api';
import { localeES, localeFR } from "./primelocale";
const keyLocale = "lang"
export const langs:any={
  es:{ name: 'Español', code: 'PE', lang:"es" },
  en:{ name: 'English', code: 'US', lang:"en" },
  fr:{ name: 'Français', code: 'FR', lang:"fr" },
}

export const locales:any = {
    es: "Español",
    en: "English",
    fr: "Français"
  }
  export const defaultLocale = "es"
  const DEFAULT_FALLBACK = () => defaultLocale;
  export const detectedLocale : any = detect(fromUrl(keyLocale ), fromStorage(keyLocale), fromNavigator(), DEFAULT_FALLBACK);

  
  const catalogs: Record<string, ()=>Promise<Messages>>={
    es: async ()=>{
        const { messages } = await import(
          // @ts-ignore
          `./file.js!=!@lingui/loader!./locales/es/messages.po`
        );
        return messages
    },
    en: async ()=>{
      const { messages } = await import(
        // @ts-ignore
        `./file.js!=!@lingui/loader!./locales/en/messages.po`
      );
      return messages
    },
    fr: async ()=>{
      const { messages } = await import(
        // @ts-ignore
        `./file.js!=!@lingui/loader!./locales/fr/messages.po`
      );
      return messages
    }

  }
  function determinateLocale(_locale: string){
    const locale:string  = langs[_locale] ? langs[_locale].lang: defaultLocale
    return locale;
  }
  export async function dynamicActivate(_locale: string) {
    const locale = determinateLocale(_locale)
    const messages = await catalogs[locale]()
    i18n.loadAndActivate({ locale, messages })
    localStorage.setItem(keyLocale, locale);
    
    addLocale("es", localeES)
    addLocale("fr", localeFR)
    return _locale;
  }
