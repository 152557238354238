import { useEffect, useState } from "react"
import useCartMonaterio from "../../contexts/useCartMonaterio";
import { t } from "@lingui/macro";
import { Skeleton } from 'primereact/skeleton';
import { TicketService } from "../../services";
import { useNavigate, useParams } from "react-router-dom";
import { useLingui } from "@lingui/react";

const ViewVideo = ()=>{
  const { i18n } = useLingui();
  const { ordernumber, item } = useParams()
  const navigate = useNavigate();
  const { setHeaderPage } = useCartMonaterio()
  const [blocked, setBlocked] = useState(true);
  const [videoUrl, setVideoUrl] = useState();
  useEffect(()=>{
    setHeaderPage({title:t`Visita Virtual`, preview:"/"});
    TicketService
    .GetUrlVideo(ordernumber, item, i18n.locale)
    .then(({data: {urlVideo, success}}:any)=> {
      if(success) setVideoUrl(urlVideo); 
      else navigate("/");
    })
    .finally(()=>setBlocked(false))
  },[])
  return<>
    <div id="VIDEO-RB" >
      <div className="container-rb pt-4">
        <div className="grid m-0 pt-8 pb-3">
          <div className="col-12 text-center">
          </div>
        </div>  
        <div className="grid m-0  flex align-items-stretch flex-wrap">
          <div className="field col-12 text-center">
            { blocked && <Skeleton width="100%" height="25rem"></Skeleton> }
            {
              !blocked && 
              <>
                <div style={{"padding":"56.25% 0 0 0","position":"relative"}}>
                  <iframe src={videoUrl} 
                          frame-border="0" allow="autoplay; fullscreen; picture-in-picture" 
                          style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} title={t`PUERTA ABIERTA ENTRE DOS MUNDOS`}>
                  </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>    
              </>
            }
          </div>
        </div>      
      </div>
    </div>
  </>
}
export { ViewVideo }