import React, { useEffect, useState } from 'react'
import { Galleria } from 'primereact/galleria';
import { TabView, TabPanel } from 'primereact/tabview';
import { t } from '@lingui/macro';

import { useVisitTranslate } from './hooks/useVisitTranslate';


const itemTemplate = (item: any) => {
  return <img src={item.itemImageSrc} alt={item.alt} style={{ width: '100%' }} />
}

const thumbnailTemplate = (item: any) => {
  return <img src={item.thumbnailImageSrc} alt={item.alt} />
}

export  const InPersonVisitInfo = ({visitData}:any) => {
  
  const { images, about, description } = useVisitTranslate(visitData.metadata)
 

  return (
    <>
      <div className="">
        <Galleria value={images} numVisible={5} circular style={{ maxWidth: '100%' }}
            showItemNavigators showItemNavigatorsOnHover showIndicators
            showThumbnails={false} item={itemTemplate} thumbnail={thumbnailTemplate} />
      </div>
      <div className="mt-3">
          <TabView>
              <TabPanel header={t`Sobre la visita`}>
                  {
                    about ? <div dangerouslySetInnerHTML={{__html:about}} ></div> :
                    <>
                      {
                        /*<p className="mt-0 text-justify">
                        La visita requiere de una hora como mínimo. El monasterio fue fundado en 1579, construido en sillar y tiene una extensión de más de 20,000 m2.
                        </p>
                        <p className="mt-0 text-justify">
                            El servicio de guiado es opcional, no está incluido en el precio de la entrada, puedes contratar una guía especializada al momento de ingresar.
                            Contamos con un aplicativo gratuito en castellano, inglés y francés, puedes descargarlo en tu celular haciendo <a href="https://play.google.com/store/apps/details?id=com.paulonia.santacatalina" target="_blank">click aquí.</a>
                        </p>
                        <p className="mt-0 text-justify">
                            Contamos con WiFi gratis.
                        </p>*/
                      }
                    </>
                  }
                  
              </TabPanel>
              <TabPanel header={t`Información Adicional`}>
                {
                  description ?  <div dangerouslySetInnerHTML={{__html:description}} ></div> :
                  <>
                    {
                      /*<p className="mt-0 text-justify">
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                        eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo
                        enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui
                        ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                      </p>*/
                    }
                  </>
                }
                  
              </TabPanel>
          </TabView>
      </div>
    </>
  )
}
