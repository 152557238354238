import React, { useState } from 'react'
import { Trans } from "@lingui/macro";
import { PAYMETHOD } from './ConstEnum'

export default function FormPaymentMethod({onChangePayMethod, method = PAYMETHOD.IZIPAY}:any) {

  
  const [payMethod, setPayMethod]:any = useState(method)
  const handleChangePayMethod = (type:any)=>{
    setPayMethod(type)
    onChangePayMethod && onChangePayMethod(type)
  }
  return (
    <>
     <div className="col-12 pb-3">
          <span className="font-medium"><Trans>Seleccione el medio de pago que deseas</Trans></span>
      </div>
      <div className="field col-12 pb-3 mb-0 flex md:col-7">
          <div onClick={()=>handleChangePayMethod(PAYMETHOD.IZIPAY)} className={`${payMethod=== PAYMETHOD.IZIPAY && "opt-selected-rb"} opt-rb flex-1 justify-content-end flex-wrap p-2 pt-3 border-round-sm text-center line-height-4 border-1 border-400 cursor-pointer`}>
              <img alt="" className="w-5 border-round-md md:w-3" src="/logo-izipay.png" />
              <span className="font-medium inline-block w-full line-height-2"><Trans>Tarjeta de crédito/débito</Trans></span>
          </div>
          {/*<div onClick={()=>handleChangePayMethod(PAYMETHOD.PAGOEFECTIVO)} className={`${payMethod=== PAYMETHOD.PAGOEFECTIVO && "opt-selected-rb"} opt-rb flex-1 justify-content-end flex-wrap p-2 pt-3 border-round-sm text-center line-height-4 border-1 border-400 cursor-pointer ml-2 md:ml-3`}>
              <img alt="" className="w-5 border-round-md md:w-3" src="/logo-pagoefectivo.png" />
              <span className="font-medium inline-block w-full line-height-2"><Trans>Banca móvil</Trans></span>
          </div>*/
          }
      </div>
      {
        
          /*
          <div className="col-12 pb-3">
        <span className='text-xs'>* <Trans>Page con Pago Efecto solamente por compras mayores a 70.00 PEN</Trans></span>
      </div>
        */
      }
      
    </>
  )
}
