import axios from "axios";


const GetCompany = async (ruc: string) => {
    const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://service.solutions-ns.com/ServiceVTA-PROTUR/api/VentasAPI/validarruc?x_token=B5E49A0C-299F-4457-A31D-24017C902FA7&x_ruc=${ruc}`,
        headers: { }
    };    
    const result = await axios.request(config)
    return result;
};

export {
    GetCompany
}
