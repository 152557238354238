import { useLocalStorage } from "primereact/hooks";
import { createContext, useContext, useEffect, useState } from "react";
import { LoginConsts } from "./login-consts";
import { AuthenticationResult } from "./authenticationResult";
import axios from "axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";



export interface IAutorizationContext {
  autenticate:any;
  logOff(): void;
  accessToken: string, 
  login: string,
  
}

const AutorizationContext = createContext<IAutorizationContext | undefined>(undefined);

const useAutorizationContext = () : IAutorizationContext =>{
  const context = useContext(AutorizationContext);
  if (!context){
      throw new Error('useAutorizationContext must be used within a AutorizationContext');
  }
  return context;
}

const AutorizationProvider = (props: any)=>{
  const [showMessage, setShowMessage] = useState(false)
  const [accessToken, setAccessToken]: any = useLocalStorage(null, LoginConsts.ACCESS_TOKEN_KEY);
  const [login, setLogin] = useLocalStorage("", LoginConsts.USERNAME_KEY);

  useEffect(()=>{
    axios.interceptors.response.use((response:any):any=>{
      return response;
    },
    (error:any)=>{
      if (401 === error?.response?.status){
        setAccessToken(null)
        setLogin("")
        setShowMessage(true)
      }
      return Promise.reject(error);
    })
  },[])
  

  const autenticate = (
    authenticationResult: AuthenticationResult,
    login: string
  ) =>{
    setAccessToken(authenticationResult.access_token);
    setLogin(login);
  }
  
  
  const logOff = ()=>{
    setAccessToken(null);
    setLogin("");
  }

  const AutorizationValue : IAutorizationContext = {autenticate, logOff, accessToken, login}

  return(
    <AutorizationContext.Provider value={AutorizationValue}  >
      {props.children}
      
      {!showMessage ||
       <Dialog visible={true} onHide={() =>{}} position="top" footer={<div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => window.location.href="/" } /></div>} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
       <div className="flex align-items-center flex-column pt-6 px-3">
           <i className="pi pi-exclamation-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
           <h5>!Sesión Expirada!</h5>
           <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
             El tiempo de sessión a expirado, por favor, vuelva a intentarlo.
           </p>
       </div>
    </Dialog>
      }
    </AutorizationContext.Provider>
  )
}
export { AutorizationProvider, useAutorizationContext}