import React, { useEffect, useState } from 'react'
import { stepItems, useSteps } from './../StepItems';
import { Steps } from 'primereact/steps';
import { TicketService } from '../../../services';
import {  useParams } from 'react-router-dom';
import { Trans, t } from "@lingui/macro";
import { BlockSpinner, InfoOrder } from '../../../componentes';
import useCartMonaterio from '../../../contexts/useCartMonaterio';
import { CartOrderInfo } from '../../../componentes/cart-order-info';
import { useLingui } from '@lingui/react';

export default function CartRejectedOrder() {
  useLingui();
  const { ordernumber } = useParams()
  const [order, setOrder]:any = useState();
  const [blocked, setBlocked] = useState(false);
  const { setHeaderPage } = useCartMonaterio()
  const { steps } = useSteps(1);
  useEffect(()=>{
    setBlocked(true)
    TicketService
    .GetOrderRefushed(ordernumber)
    .then((res)=> {setOrder(res.data); return res;})
    //.then((res)=>{!res.data ? navigate("/"):void(0)})
    .finally(()=>setBlocked(false));
    setHeaderPage({title:t`Carrito de compras`, preview:"/"});
  },[]);
  
  return (
    <div id="CHECKOUT-3-RB" >
      <div className="container-rb pt-7 pb-4 surface-100">
          <Steps model={steps}  activeIndex={2}  className="pt-7" />
      </div>
      <div className="container-rb pt-5">
          <div className="grid m-0">
              <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
                  <div className="grid mx-0 md:px-4">
                      <div className="col-12 pb-3 md:pb-2">
                          <h2 className="mt-0"><Trans>Orden Rechazada</Trans> #{order?.codigo}</h2>
                          <div className="text-lg"><Trans>Su orden ha sido rechazada, por favor comuniquese con su banco </Trans> {order?.paymentIzipay && order?.paymentIzipay.reasonDeclinedPayment} </div>
                      </div>
                      <InfoOrder orderSelected={order} showDownload={false} showViewVisitor={false} />
                  </div>
              </div>
              <CartOrderInfo />
          </div>
      </div>
      <BlockSpinner uiBlocked={blocked} />
    </div>
  )
}
