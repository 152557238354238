import { useState } from "react";
import { EVisistService } from '../../services/EVisistService';

const useVisit =(id:any)=>
{
  const [visitTotal, setVisitTotal] =   useState(0)
  const [excludedDates, setExcludedDates] = useState([]);
  const [months, setMonths]:any = useState({});
  const [scheduler, setScheduler]:any = useState({today:new Date(), maxDate:new Date()});
  const [sessionsDay, setSessionsDay]:any = useState({sessions:[]});
  const [prices, setPrices]:any[] = useState([]);
  const [viewDate, setViewDate] = useState(new Date());
  const [blocked, setBlocked] = useState(false)
  const callScheduler = (dateView:Date)=>{
    setBlocked(true);
    EVisistService
    .getSheduler(id, dateView.getFullYear(), dateView.getMonth() + 1,4)
    .then((_scheduler:any)=>{
      setViewDate(dateView);
      updateSchedueler(_scheduler.data);
    }).finally(()=> setBlocked(false));
  }
  const updateSchedueler = (_scheduler:any):void=>{
    _scheduler.today = new Date(_scheduler.today);
    _scheduler.maxDate = new Date(_scheduler.maxDate);
    setScheduler(_scheduler)
    _scheduler.months.map((m:any)=>{
      m.date = new Date(m.date);
      months[`${m.date.getFullYear()}${m.date.getMonth()}`] = m
      setMonths(months);
      return m;
    })
    const dates:any = Object
    .values(months)
    .reduce((p:any,c:any)=>[...p,...c.excludeDays.map((d:any)=> new Date(c.date.getFullYear(), c.date.getMonth(),d))],[])
    
    setExcludedDates(dates)
    
  }

  const calculateTotals =(prices:any, dateScheduler:any)=>{
    
    let totalAmount = 0;
    prices.map((price:any)=>{
      price.totalCount = price.priceAges.reduce((a:any, v:any)=> a + (v.count || 0),0);
      totalAmount += price
                  .priceAges
                  .reduce((a:any, priceAge:any)=> 
                  {
                    const discount = resolveDiscount(priceAge, price.typeNationality, dateScheduler)
                    return a + ((priceAge.count||0)*(priceAge.price - (discount ? discount.discount:0) || 0))
                  },0);
      return price;
    })
    setPrices([...prices]);
    setVisitTotal(totalAmount)
  }
  const resolveDiscount = (priceAge: any, typeNationality:any, dateScheduler:any) =>
  {
    if (priceAge.discount) return priceAge.discount;
    
    const discountAssigned = scheduler
    .asignedDateDiscounts
    ?.find((d:any)=>{
      return dateScheduler >= new Date(d.fechaDesde) &&
             dateScheduler <= new Date(d.fechaHasta) &&
             typeNationality == d.tipoNacionalidad &&
             priceAge.ageType == d.tipoEdad
    });
    console.log("discountAssigned", discountAssigned)
    console.log("dateScheduler", dateScheduler)
    if (discountAssigned){
      return { item:discountAssigned.item,
            discount: discountAssigned.descuento}
    }
    
  }
  const filterSessions = (date:Date):any=>{
    const month = months[`${date.getFullYear()}${date.getMonth()}`]
    if (month){
      const _sessionsDay = month.sessionDays.find((s:any)=>s.dayOfMonth===date.getDate())
      const value = _sessionsDay || {sessions:[]}
      setSessionsDay(value);
      return value;
    }
  }
  return {
    visitTotal, setVisitTotal,
    resolveDiscount,
    excludedDates, setExcludedDates,
    months, setMonths,
    scheduler, setScheduler,
    sessionsDay, setSessionsDay,
    prices, setPrices,
    viewDate, setViewDate,
    calculateTotals, callScheduler, updateSchedueler, filterSessions, blocked
  }
}

export default useVisit;