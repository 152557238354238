import React, { useState } from 'react'
import { Password } from 'primereact/password';

import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import { Controller, useForm } from 'react-hook-form';
import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { BlockSpinner } from '../../componentes';
import { UserService } from '../../services';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import { Dialog } from 'primereact/dialog';

export default function RestorePasswordForm() {

  

  const schema = z.object({
    password: z.string({required_error:t`Escriba una contraseña nueva`}).regex(/^(.){5,200}$/, t`La contraseña debe ser minimo de 5 caracteres alphanumerico`),
    passwordRepeat: z.string({required_error:t`Escriba de nuevo la contraseña`})
  }).superRefine((values,c)=>{
    values.password === values.passwordRepeat ||
    c.addIssue({
      message: t`La contraseña confirmada debe ser igual a la contraseña ingresada`,
      code: z.ZodIssueCode.custom,
      path: ['passwordRepeat'],
    });
  });
  const { handleSubmit, control, formState: { errors } }:any = useForm({defaultValues:{password:"", passwordRepeat:""}, resolver:zodResolver(schema)})
  
  const [blocked, setBlocked] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const getFormErrorMessage = (name:any) => {
    return errors[name] && <small className="p-error">{errors[name].message}</small>
  };
  const onSubmit = (e:any)=>{
    setBlocked(true)
    const key = searchParams.get("k")
    const req = {
      keyRecovery:key,
      password: e.password
    }
    UserService.recoveryPassowrReset(req)
    .then((r)=> setShowMessage(true))
    .finally(()=>setBlocked(false))
  }
  const handleClose = ()=>{
    navigate("/")
  }
  return (
    <Sidebar blockScroll={true} dismissable={true} visible={true} position="right" onHide={() => handleClose()} className="w-full md:w-3">
       <div id="SING-IN" className="">
        <h3><Trans>Cambie su contraseña</Trans></h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formgrid grid">
              <div className="field col-12">
                  <span className="font-medium opacity-70"><Trans>Ingrese una contraseña nueva.</Trans></span>
              </div>
              <div className="field col-12">
                  <Controller name="password" control={control} render={({ field }) => (
                    <span className="p-input-icon-left w-full">
                        <i className="pi pi-lock" />
                        <Password id={field.name} {...field} feedback={false}  toggleMask minLength={4}  maxLength={25} placeholder={t`Contraseña`} className="w-full" inputClassName="w-full"  />
                    </span>
                  )} />
                  {getFormErrorMessage("password")}
                    
                </div>
                <div className="field col-12">
                    
                    <Controller name="passwordRepeat" control={control} render={({ field }) => (
                      <span className="p-input-icon-left w-full">
                        <i className="pi pi-lock" />
                        <Password id={field.name} {...field} feedback={false} toggleMask minLength={4} maxLength={25} placeholder={t`Confirmar contraseña`}  className="w-full" inputClassName="w-full"  />
                      </span>
                    )} />
                    {getFormErrorMessage("passwordRepeat")}
                </div>
              <div className="field col-12">
                  <Button className="font-semibold w-full" label={t`Cambiar Contraseña`} />
              </div>

          </div>
        </form>
        <BlockSpinner uiBlocked={blocked} />
      </div>
      <Dialog visible={showMessage} onHide={() =>{}} position="top" footer={<div className="flex justify-content-center"><Button label="OK" className="p-button-text" autoFocus onClick={() => handleClose() } /></div>} showHeader={false} breakpoints={{ '960px': '80vw' }} style={{ width: '30vw' }}>
       <div className="flex align-items-center flex-column pt-6 px-3">
           <i className="pi pi-exclamation-circle" style={{ fontSize: '5rem', color: 'var(--green-500)' }}></i>
           <h5><Trans>!Contraseña Cambiada!</Trans></h5>
           <p style={{ lineHeight: 1.5, textIndent: '1rem' }}>
             <Trans>Su contraseña fue restablecida, inicie sesión con su nueva contraseña</Trans>
           </p>
       </div>
    </Dialog>
    </Sidebar>
   
  )

}
