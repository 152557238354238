import React, { useState } from 'react'
import { Trans } from "@lingui/macro";
import { ReturnsPolitics } from '../politics/returns-politic'
import { TermsPolitics } from '../politics/terms-politics'
import { Link } from 'react-router-dom';
export default function Footer() {
  const [showReturnsPolitics, setShowReturnsPolitics] = useState(false);
  const [showTermsPolitics, setShowTermsPolitics] = useState(false);
  return (
    <>
        <footer className="container-rb mt-6 text-white text-center pt-5 pb-7 md:text-left">
        <div className="grid m-0">
          <div className="col-12 md:col-4">
            <h3><Trans>Sobre el sitio</Trans></h3>
            <ul className="m-0 p-0 list-none">
              <li className="inline-block w-full">
                <a onClick={()=>setShowReturnsPolitics(true)} className=' cursor-pointer text-white no-underline inline-block w-full py-2'><Trans>Política de devoluciones</Trans></a>
                
              </li>
              <li className="inline-block w-full">
                <a onClick={()=>setShowTermsPolitics(true)} className=" cursor-pointer text-white no-underline inline-block w-full py-2"><Trans>Términos y condiciones</Trans></a>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-4">
            <h3><Trans>Contáctanos</Trans></h3>
            <ul className="m-0 p-0 list-none">
              <li className="inline-block w-full">
                <a href="tel:+51-54221213" className="text-white no-underline inline-block py-2"><i className="pi pi-phone mr-2"></i>+51 - 54 221213</a>
              </li>
              <li className="inline-block w-full">
                <a href="tel:+51-54221234" className="text-white no-underline inline-block py-2"><i className="pi pi-phone mr-2"></i>+51 - 54 221234</a>
              </li>
              <li className="inline-block w-full">
                <a href="tel:+51-54221235" className="text-white no-underline inline-block py-2"><i className="pi pi-phone mr-2"></i>+51 - 54 221235</a>
              </li>
              <li className="inline-block w-full">
                <a href="mailto:informes@santacatalina.org.pe" className="text-white no-underline inline-block py-2"><i className="pi pi-envelope mr-2"></i>informes@santacatalina.org.pe</a>
              </li>
              <li className="inline-block w-full">
                <a href="https://maps.app.goo.gl/5DR6EqzMfnNUSv556" target="_blank" className="text-white no-underline inline-block py-2"><i className="pi pi-map-marker mr-2"></i>Santa Catalina 301 Arequipa, Perú</a>
              </li>
            </ul>
          </div>
          <div className="col-12 md:col-4">
            <h3><Trans>Síguenos en</Trans></h3>
            <div className="flex justify-content-center flex-wrap text-center md:justify-content-start">
              <a href="https://www.facebook.com/monasteriodesantacatalina" target='_blank' className="social text-white"><i className="pi pi-facebook mx-1"></i></a>
              <a href="https://www.instagram.com/monasteriodesantacatalina" target='_blank' className="social text-white"><i className="pi pi-instagram mx-1"></i></a>
              <a href="https://www.youtube.com/monasteriodesantacatalina" target='_blank' className="social text-white"><i className="pi pi-youtube mx-1"></i></a>
            </div>
          </div>
          <div className="col-12 opacity-60 md:col-3 md:text-right hidden">
            <img alt="" className=" mt-3 w-11 md:w-full" src="/logo-2-white.svg" />
            <span className="mt-4">© 2023</span>
          </div>
        </div>
        <div className="grid m-0 mt-5 opacity-60">
          <div className="col-12 md:col-3">
            <img alt="" className="w-11 md:w-full" src="/logo-2-white.svg" />
          </div>
          <div className="col-12 md:col-9 md:text-right">
            <span>© 2023</span>
          </div>
        </div>
      </footer>
      {showReturnsPolitics && <ReturnsPolitics onHide={()=> setShowReturnsPolitics(false)} />}
      {showTermsPolitics && <TermsPolitics onHide={()=> setShowTermsPolitics(false)}  />}
      
    </>
  )
}
