import axios from "axios";
import { LoginConsts } from "../autorization"
import urlBase from './Configuration'
import { getHeaders } from "./TokenHeader";
//const urlBase = 'https://localhost:44324';

class TicketService {

  public static GetUrlVideo(orderNumber:any, item:any, locate: any){
    return axios.get(`${urlBase}/ticket/tickets/${orderNumber}/${item}/virtual?locale=${locate}`)
  }

  public static MarkAsEntered(orderNumber:any, item:any, nota: any){
    return axios.post(`${urlBase}/ticket/tickets/${orderNumber}/${item}/enter`,{nota}, {headers: getHeaders()})
  }

  public static GetOrderConfirmed(orderNumber:any){
    return axios.get(`${urlBase}/ticket/tickets/${orderNumber}/confirmed?withDetails=true`, {headers: getHeaders()})
  }

  public static GetMyOrderConfirmed(orderNumber:any){
    return axios.get(`${urlBase}/ticket/tickets/${orderNumber}/my/confirmed?withDetails=true`, {headers: getHeaders()})
  }

  public static GetOrderRefushed(orderNumber:any){
    return axios.get(`${urlBase}/ticket/tickets/${orderNumber}/my/refushed?withDetails=true`, {headers: getHeaders()})
  }

  public static GetOrderPendings(orderNumber:any){
    return axios.get(`${urlBase}/ticket/tickets/${orderNumber}/my/pending?withDetails=true`, {headers: getHeaders()})
  }

  public static GetAllMyOrders(){
    return axios.get(`${urlBase}/ticket/tickets/my`, {headers: getHeaders()})
  }

  public static DownloadTicket(orderNumber:any, item:any, locale:any){
    return axios({
      url: `${urlBase}/ticket/${orderNumber}/${item}/content?locale=${locale}`,
      method: 'GET',
      responseType: 'blob', // Important
      headers: getHeaders()
    });
    
  }

}
export { TicketService }