import axios from 'axios';
import urlBase from './Configuration'
//const urlBase = 'https://localhost:44324';
const EVisistService ={
    getVisits(): Promise<any>{
        return axios.get(`${urlBase}/evisit`);
    },
    getVisit(id:any): Promise<any>{
        return axios.get(`${urlBase}/evisit/${id}`);
    },
    getTodaySheduler(visitId: any): Promise<any>{
        return axios.get(`${urlBase}/evisit/${visitId}/scheduler`);
    },
    getSheduler(visitId: any, year:number, month:number, count:any): Promise<any>{
        if (!count)
            return axios.get(`${urlBase}/evisit/${visitId}/scheduler/${year}/${month}`);
        else
            return axios.get(`${urlBase}/evisit/${visitId}/scheduler/${year}/${month}?count=${count}`);
    },
    isVisitRange(id:any):boolean{
      return "E18E1679-F7AD-4507-959F-088102E4B15A".toLowerCase() === (id?.toLowerCase()) ||
      "5B1496E7-0932-457B-A176-FFD30E890A8A".toLowerCase() === (id?.toLowerCase())
    }
}

const visits:any = {}

const getVisit =(id:string): Promise<any>=>{
  const v = visits[id.toLocaleLowerCase()]
  if (v) return Promise.resolve(v)
  else{ 
    return new Promise((resolve:any, reject:any)=>{
      EVisistService
        .getVisit(id)
        .then((res)=>{
          visits[id.toLocaleLowerCase()] = res.data
          resolve(res.data);
        })
        .catch((err)=>reject(err))
    })
  }
}

export { EVisistService, getVisit }