import React, { useEffect, useRef, useState } from 'react'
import { Steps } from 'primereact/steps';
import { useSteps } from './../StepItems';
import ViewItemsAdded from  './ViewItemsAdded'
import { Button } from 'primereact/button';
import { Trans, t } from "@lingui/macro";
import useCartMonaterio from './../../../contexts/useCartMonaterio'
import { i18n } from "@lingui/core";
import {  useNavigate } from "react-router-dom";
import { useAuthenticationService }  from '../../../autorization'
import { LoginControl } from '../../../componentes';
import { useLingui } from '@lingui/react';
import { Toast } from 'primereact/toast';

const CartProducts = () => {
  useLingui()
  const toast:any = useRef(null);
  const { steps } = useSteps(0);
  const [showLogin, setShowLogin] = useState(false);
  const { isAuthenticated, isGuest } = useAuthenticationService();
  const navigate = useNavigate()
  const { total, setHeaderPage } = useCartMonaterio()
  
  useEffect(()=>{
    setHeaderPage({title:t`Carrito de compras`, preview:"/"});
  },[i18n.locale])
  const validate =()=>{
    if (!total || total.totalPrice <=0 || total.productsQuantity<=0){
      toast.current.show({severity:'error', summary: t`Productos`, detail:t`Debe agregar productos para continuar`, life: 3000});
      return false;
    }
    return true;
  }
  const nextStept = ()=>{
    if (validate()){
      !isAuthenticated() || isGuest() ? setShowLogin(true): navigate("/checkout/checkout");
    }
    
  }
  
  return (
    <div id="CHECKOUT-1-RB" className="">
      <div className="container-rb pt-7 pb-4 surface-100">
        <Steps model={steps} activeIndex={0} className="pt-7" />
      </div>
      <div className="container-rb pt-5">
        <div className="grid m-0">
          <div className="col-12 md:col-8 border-bottom-1 border-300 mb-3 pb-4 pt-0 md: mb-0 md:pb-0 md:pr-0 md:border-right-1 md:border-bottom-none">
            <div className="grid mx-0 md:px-4">
              <div className="col-12 pb-3 md:pb-4">
                <h3 className="my-0"><Trans>Productos</Trans><span className="font-normal ml-1">({total?.productsQuantity || 0})</span></h3>
              </div>
              <ViewItemsAdded />
            </div>
          </div>
          <div className="col-12 md:col-4">
            <div className="grid mx-0 md:p-3 md:ml-4 md:shadow-2 border-round-xl">
                <div className="col-12"><h3 className="my-2 md:mt-0"><Trans>Resumen de tu compra</Trans></h3></div>
                <div className="col-7 text-lg font-medium"><Trans>Total a pagar</Trans></div>
                <div className="col-5 text-lg font-bold text-right">{ i18n.number(total.totalPrice, { style: "currency", currency:"PEN" })}</div>
                <div className="col-12">
                    <Button onClick={()=>nextStept()} className="font-semibold w-full" size="large" icon="" label={t`Continuar con la compra`} />
                </div>
            </div>
          </div>
        </div>
      </div>
      <LoginControl 
        nonRoute={true} 
        urlAutecticated='/checkout/checkout' 
        showLogin={showLogin} 
        onClose={()=> setShowLogin(false)}  />
      <Toast ref={toast} />
    </div>
  )
}
export default CartProducts